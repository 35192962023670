import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { HttpHeaders, HttpClient } from '@angular/common/http';
//import { JwtHelperService } from '@auth0/angular-jwt';
import { tap, first } from 'rxjs/operators';
import firebase from 'firebase/app';
import * as moment from 'moment';

import { environment } from 'src/environments/environment';
//Services
import { StorageService } from '../storage/storage.service';
import { RouterService } from '../router/router.service';
import { PlatformService } from '../platform/platform.service';
import { LoaderService } from '../loader/loader.service';
import { AlertService } from '../alert/alert.service';
import { ExceptionsService } from 'src/app/exceptions/exceptions.service';
import { UserService } from '../user/user.service';
import { SafeScript } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import * as fbAuth from '@ionic-native/firebase-authentication';
import { IParamsAuth } from 'src/app/interfaces/IParamsAuth';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { TagsService } from '../tags/tags.service';
import { EmblueService } from './../emblue/emblue.service';
import { IRegisterConfig, IRegisterUser, ICheckUser, IChangePassword } from 'src/app/interfaces/IAuth';
import { PushNotificationsManagerService } from 'src/app/components/push-notifications-manager/push-notifications-manager.service';

//const helperJWT = new JwtHelperService();
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  fbTokenJWT: string;
  headers = new HttpHeaders();
  currentAuthTokenValue: string;
  firebaseUid;
  recaptchaVerifier;
  //  methodAuth: string;
  emailGoogle: string;
  userGnxId: string = '';
  apiKey: string;
  programId;
  //component register
  mode: string = 'initial';
  showPasswordInput: boolean = false;
  methodAuth: string;
  uid: any;
  phone;
  gnxCredential1;
  paramsAuth: IParamsAuth = { oobCode: '', mode: '' };
  constructor(
    private platformService: PlatformService,
    private angularFireAuthService: AngularFireAuth,
    private http: HttpClient,
    private alertService: AlertService,
    private exceptionService: ExceptionsService,
    private storageService: StorageService,
    private loaderService: LoaderService,
    private routerService: RouterService,
    private utilService: UtilsService,
    private modalController: ModalController,
    private tagsService: TagsService,
    private pushNotificationsManagerService : PushNotificationsManagerService
  ) {
    this.headers = this.headers.append('X-Skip-Interceptor', '');
    this.saveGlobalUser();
  }
  async saveGlobalUser() {
    const id = await this.storageService.get("gnx-account-id");
    const claims = await this.storageService.get("gnx-claims");
    if(claims){
      const programId = claims.programs;
      const phone = claims.phone;
      if (id && programId) {
        this.userGnxId = id;
        this.programId = programId;
        //this.utilService.setGlukyUserId(id);
      }
      this.phone = claims.phone ? claims.phone.replace('+57','') : null;
      this.gnxCredential1 = claims.username;
    }
  }

  async loginWithUserAndPassword(user: string, password: string) {
    const authentication =
      await this.angularFireAuthService.signInWithEmailAndPassword(
        user,
        password
      );
    return authentication;
  }

  async callFbToken() {
    this.fbTokenJWT = await firebase.auth().currentUser.getIdToken();
    return this.fbTokenJWT;
  }

  getAccessFromUsers(fbTokenJWT, username?): Promise<any> {
    const { headers } = this;
    const { app_id, apiKeyWeb } = environment.gluky;

    return new Promise((resolve, reject) =>
      this.fetchAccessJwt(app_id, apiKeyWeb, headers, fbTokenJWT, username).subscribe(
        async (res: {
          match: boolean;
          gn_token: string;
          users?: [];
          account: any;
        }) => {
          if (res.users && res.users.length > 0) {
            resolve(res.users);
          }
          if (res.match && res.gn_token) {
            await this.saveUserGlobalId(res.account.id);
            resolve(res.gn_token);
          }
        },
        (err) => {
          reject(err);
        }
      )
    );
  }

  fetchAccessJwt(
    app_id: string,
    apikey: string,
    headers: any,
    fbTokenJWT: any,
    username?: any
  ) {
    const params: any = {
      app_id,
      app_jwt: fbTokenJWT,
    };
    if (username && username != undefined && username != null) {
      params.username = username;
    }

    return this.http.post(
      `${environment.gluky.usersApiBasePath}/auth/access-jwt?key=${apikey}`,
      {
        ...params,
      },
      {
        headers,
      }
    );
  }

  checkAccessFromUsers(username, password) {
    const params = {
      username,
      password,
      app_id: environment.gluky.app_id,
    };
    const apikey = this.apiKey;
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${environment.gluky.usersApiBasePath}/auth/access-jwt?key=${apikey}`,
          {
            ...params,
          }
        )
        .subscribe(
          (ans) => resolve(ans),
          (err) => reject(err)
        );
    });
  }

  linkUserOnGnx(fbTokenJWT: any, username: any, password: any) {
    const params = {
      username,
      password,
      app_id: environment.gluky.app_id,
      app_jwt: fbTokenJWT,
    };
    const apikey = this.apiKey;
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${environment.gluky.usersApiBasePath}/auth/access-jwt?key=${apikey}`,
          {
            ...params,
          }
        )
        .subscribe(
          async (ans: any) => {
            await this.saveUserGlobalId(ans.account.id);
            resolve(ans);
          },
          (err) => reject(err)
        );
    });
  }

  linkEmailOnGnx(fbTokenJWT: any, email: string) {
    const params = {
      email,
      app_id: environment.gluky.app_id,
      app_jwt: fbTokenJWT,
    };
    const apikey = this.apiKey;
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${environment.gluky.usersApiBasePath}/auth/access-jwt?key=${apikey}`,
          {
            ...params,
          }
        )
        .subscribe(
          async (ans: any) => {
            await this.saveUserGlobalId(ans.account.id);
            resolve(ans);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  linkPhoneOnGnx(fbTokenJWT, phone) {
    const params = {
      phone,
      app_id: environment.gluky.app_id,
      app_jwt: fbTokenJWT,
    };
    const apikey = this.apiKey;
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${environment.gluky.usersApiBasePath}/auth/access-jwt?key=${apikey}`,
          {
            ...params,
          }
        )
        .subscribe(
          async (ans: any) => {
            await this.saveUserGlobalId(ans.account.id);
            resolve(ans);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  async saveUserGlobalId(uid) {
    await this.storageService.set('gnx-account-id', uid);
    this.userGnxId = uid;
  }


  async removeUserFromFirebase() {
    const user: firebase.User = await this.angularFireAuthService.currentUser;
    return await user.delete();
  }

  async unsubscribeUserFromGnx() {
    this.loaderService.presentLoading('unsubscribeUserFromGnx');
    return new Promise((resolve, reject) => {
      this.http
        .post(`${environment.gluky.usersApiBasePath}/auth/unsubscribe`, {})
        .subscribe(
          async (ans: any) => {
            resolve(ans);
            this.loaderService.cancelLoader('unsubscribeUserFromGnx');
          },
          (err) => {
            reject(err);
            this.loaderService.cancelLoader('unsubscribeUserFromGnx');
            this.exceptionService.renderError(err);
          }
        );
    });
  }

  async logout() {
    await this.pushNotificationsManagerService.deleteToken();
    this.pushNotificationsManagerService.deleteInfoCache();
    await this.angularFireAuthService.signOut();
    await this.storageService.removeAll();
    localStorage.clear();
    sessionStorage.clear();
    this.programId = "";
    this.currentAuthTokenValue = "";
    this.routerService.navigate("/login", { replaceUrl: true });
  }

  verifyTimeStampToken = () => {
    const tokenTimeStamp = this.utilService.parseJwt(
      this.currentAuthTokenValue
    ).exp;
    const actualDate = moment().unix();

    return tokenTimeStamp <= actualDate;
  };

  async verifyEmail(params) {
    return this.angularFireAuthService.applyActionCode(params.oobCode);
  }

  async refreshToken() {
    let token = await this.storageService.get('gnx-token');
    if (!token) {
      return null;
    }
    this.currentAuthTokenValue = token;
    return this.verifyTimeStampToken()
      ? this.refreshTokenAndSaveOnLocalStorage()
      : token;
  }

  refreshTokenAndSaveOnLocalStorage = async () => {
    try {
      const fbtokenJWT = await this.callFbToken();
      const newToken = await this.getAccessFromUsers(fbtokenJWT);
      await this.storageService.set('gnx-token', newToken);
      this.currentAuthTokenValue = newToken;
      return newToken;
    } catch (err) {
      return err;
    }
  };

  async setCurrentTokenGnx(token: any) {
    this.currentAuthTokenValue = token;
  }

  prepareRecaptcha(buttonId: string) {
    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(buttonId, {
      size: 'invisible',
      callback: (response: any) => { },
      'expired-callback': function (responseOnCancel: any) {
        console.log(responseOnCancel);
      },
    });
  }

  makeLoginWithPhone(phoneNumber) {
    return new Promise((resolve, reject) => {
      this.angularFireAuthService
        .signInWithPhoneNumber(phoneNumber, this.recaptchaVerifier)
        .then((confirm) => {
          console.log(confirm);
          this.tagsService.saveTagInfoPublic(
            'register_otp_phone',
            'register_otp_phone_sendsms',
            phoneNumber,
            'Make login Android/web confirm ' + confirm
          );
          resolve(confirm);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  makeLoginWithPhoneIos(phoneNumber: any) {
    return new Promise((resolve, reject) => {
      fbAuth.FirebaseAuthentication.verifyPhoneNumber(phoneNumber, 30000)
        .then((snapshotID) => {
          this.tagsService.saveTagInfoPublic(
            'register_otp_phone',
            'register_otp_phone_sendsms',
            phoneNumber,
            'Make login platformIOS snapshotVID ' + phoneNumber
          );
          resolve(snapshotID);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  signWithVID(vID: any, sms: any) {
    return new Promise((resolve, reject) => {
      const phoneCredential = firebase.auth.PhoneAuthProvider.credential(
        vID,
        sms
      );
      this.angularFireAuthService
        .signInWithCredential(phoneCredential)
        .then((val) => {
          console.log(val);
          resolve(val);
        })

        // })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async deleteUserAuthFirebase() {
    const user: firebase.User = await this.angularFireAuthService.currentUser;
    return await user.delete();
  }

  getUSerInfoFromFirebase() {
    return this.angularFireAuthService.authState
      .pipe(tap(), first())
      .toPromise();
  }

  verifyPasswordResetCode(actionCode) {
    return new Promise((resolve, reject) => {
      this.angularFireAuthService
        .verifyPasswordResetCode(actionCode)
        .then((confirm) => {
          resolve(confirm);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  confirmPasswordReset(actionCode, newPass) {
    return new Promise((resolve, reject) => {
      this.angularFireAuthService
        .confirmPasswordReset(actionCode, newPass)
        .then((confirm) => {
          resolve(confirm);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getParamsAuth(emailVerification: string) {
    try {
      console.log(emailVerification);
      const indexMode = emailVerification.indexOf('&mode') + 6;
      const indexObbCode = emailVerification.indexOf('&oobCode') + 9;
      const indexContinueUrl = emailVerification.indexOf('&continueUrl');

      this.paramsAuth.oobCode = emailVerification.substring(
        indexObbCode,
        indexContinueUrl
      );
      this.paramsAuth.mode = emailVerification.substring(
        indexMode,
        indexObbCode - 9
      );
      console.log(this.paramsAuth);
    } catch (error) {
      console.error(
        'Error al tratar de obtener params Auth en aplicacion nativa'
      );
    }
  }

  async setApiKeyByPlatform() {
    const plat = this.platformService.getPlatform();
    if (
      plat.includes('mobileweb') ||
      plat.includes('desktop') ||
      plat.includes('tablet')
    ) {
      return (this.apiKey = environment.gluky.apiKeyWeb);
    }
    if (plat.includes('pwa')) {
      return (this.apiKey = environment.gluky.apikeyPwa);
    }
    if (plat.includes('android')) {
      return (this.apiKey = environment.gluky.apiKeyAndroid);
    }
    if (plat.includes('ios')) {
      //TODO add paike to ios
      return (this.apiKey = environment.gluky.apiKeyAndroid);
    }
  }

  updateProfile(data: any) {
    const apikey = environment.gluky.apikey;
    const headers = new HttpHeaders({ 'Authorization': `Bearer ${this.fbTokenJWT}` });

    return this.http.put(
      `${environment.gluky.usersApiBasePath}/auth/update-user?key=${apikey}`,
      {
        ...data
      },
      {
        headers,
      }
    );
  }

  getRegisterConfigData(data: IRegisterConfig) {
    const apikey = environment.gluky.apikey;

    return this.http.post(
      `${environment.gluky.usersApiBasePath}/auth/register-config-data?key=${apikey}`,
      {
        ...data,
      }
    );
  }

  // NEW AUTH
  registerUser(data: IRegisterUser) {
    const apikey = environment.gluky.apikey;
    const requestOptions = {
      headers: new HttpHeaders({
        'X-Skip-Interceptor': 'register'
      })
    };

    return this.http.post(
      `${environment.gluky.usersApiBasePath}/auth/register?key=${apikey}`,
      {
        ...data,
      }, requestOptions
    ).toPromise();
  }

  checkIfRegistered(data: ICheckUser) {
    const apikey = environment.gluky.apikey;

    const requestOptions = {
      headers: new HttpHeaders({
        'X-Skip-Interceptor': 'register'
      })
    };

    return this.http.post(
      `${environment.gluky.usersApiBasePath}/auth/check-if-registered?key=${apikey}`,
      {
        ...data,
      }, requestOptions
    );
  }

  changePassword(data: IChangePassword) {
    const apikey = environment.gluky.apikey;
    const headers = new HttpHeaders({ 'Authorization': `Bearer ${data.token}` });

    return this.http.post(
      `${environment.gluky.usersApiBasePath}/auth/change-password?key=${apikey}`,
      {
        'new_password': data.password,
      },
      {
        headers,
      }
    );
  }

  async sendFcmToken(token, user) {
    if (token) {
      console.log('Send FCM token Users Notifications');
      const claims = await this.storageService.get("gnx-claims");
      //const emblueToken = await this.emblueService.getToken();
      let user_email = (user.email) ? user.email : 'mobile_' + user.phone.trim() + '@nomail.com';
      var phone_user = this.validationPhone(user.phone);
      let editCustomFields = "telefono_1:|:" + phone_user + ":|:1|||nombre:|:" + user.name + ":|:1|||apellido:|:" + user.lastnames + ":|:1|||id_contacto:|:" + user.gnxCredential1 + ":|:1|||email_secundario:|:" + user_email + ":|:1|||push_fb:|:" + token + ":|:1";
      return this.http.post(
        `${environment.pathNotification}/frontend/user/${claims.uid}/register-token`,
        {
          "device": navigator.userAgent,
          "fcmToken": token,
          "customFields": editCustomFields
        }
      );
    }
  }

  validationPhone(phone) {
    try {
      if (phone) {
        var phoneRGEX = /(\+|00)(297|93|244|1264|358|355|376|971|54|374|1684|1268|61|43|994|257|32|229|226|880|359|973|1242|387|590|375|501|1441|591|55|1246|673|975|267|236|1|61|41|56|86|225|237|243|242|682|57|269|238|506|53|5999|61|1345|357|420|49|253|1767|45|1809|1829|1849|213|593|20|291|212|34|372|251|358|679|500|33|298|691|241|44|995|44|233|350|224|590|220|245|240|30|1473|299|502|594|1671|592|852|504|385|509|36|62|44|91|246|353|98|964|354|972|39|1876|44|962|81|76|77|254|996|855|686|1869|82|383|965|856|961|231|218|1758|423|94|266|370|352|371|853|590|212|377|373|261|960|52|692|389|223|356|95|382|976|1670|258|222|1664|596|230|265|60|262|264|687|227|672|234|505|683|31|47|977|674|64|968|92|507|64|51|63|680|675|48|1787|1939|850|351|595|970|689|974|262|40|7|250|966|249|221|65|500|4779|677|232|503|378|252|508|381|211|239|597|421|386|46|268|1721|248|963|1649|235|228|66|992|690|993|670|676|1868|216|90|688|886|255|256|380|598|1|998|3906698|379|1784|58|1284|1340|84|678|681|685|967|27|260|263)(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{4,20}$/;
        var phoneResult = phoneRGEX.test(phone.trim());
        console.log("phone: " + phone + " - " + phoneResult);

        if (!phoneResult) {
          phone = environment.phoneIndicator + phone;
        }
        console.log("result", phone);
        return phone;
      }
    } catch (error) {
      console.log("error indicative phone");
      return null;
    }
  }
}
