import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KpiListComponent } from './kpi-list/kpi-list.component';
import { KpiSkeletonComponent } from './kpi-skeleton/kpi-skeleton.component';
import { KpiGaugeComponent } from './kpi-gauge/kpi-gauge.component';
import { KpiDetailComponent } from './kpi-detail/kpi-detail.component';
import { KpiCarouselComponent } from './kpi-carousel/kpi-carousel.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { NgCircleProgressModule } from 'ng-circle-progress';


@NgModule({
  declarations: [
    KpiListComponent,
    KpiSkeletonComponent,
    KpiGaugeComponent,
    KpiDetailComponent,
    KpiCarouselComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    IonicModule,
    RouterModule,
    PipesModule,
    NgCircleProgressModule.forRoot({
      radius: 80,
      space: -10,
      titleFontSize: "14",
      outerStrokeColor: "#000000",
      innerStrokeColor: "#e7e8ea",
      outerStrokeWidth: 10,
      innerStrokeWidth: 10,
      animationDuration: 300,
      showImage: false,
      showSubtitle: false,
      showUnits: false,
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    KpiListComponent,
    KpiSkeletonComponent,
    KpiGaugeComponent,
    KpiDetailComponent,
    KpiCarouselComponent
  ]
})
export class KpiModule { }
