import { Pipe, PipeTransform } from '@angular/core';
import firebase from 'firebase/app';
import { environment as Constants} from '../../environments/environment';
import { Observable } from 'rxjs';

@Pipe({
  name: 'firebaseImage'
})
export class FirebaseImagePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    console.log(value,args);
    return Observable.create(observer => {
    	firebase.storage().ref().child(`${args ? args : 'codes'}/${value}.jpg`).getDownloadURL().then(url => {
           		observer.next(url);
    	  	},(err) => {
              setTimeout(() => {
                firebase.storage().ref().child(`${'codes'}/${value}.jpg`).getDownloadURL().then(url => {
                  observer.next(url);
                },(err) => {
                  observer.next("assets/spinner.gif");
                });
              }, 3000);
        });
	});
  }

}
