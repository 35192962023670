import { Injectable } from "@angular/core";
import { DatePipe } from "@angular/common";
import { AngularFirestore } from "@angular/fire/firestore";
import { Platform } from '@ionic/angular';
//Types
import { TEvent } from "src/app/interfaces/types/TEvent.type";
import { TPage } from "src/app/interfaces/types/TPage.type";
import { StorageService } from "../storage/storage.service";
import { IGnxClaims } from "src/app/interfaces/types/storage/IGnxClaims.interface";
import { TAuth } from "src/app/interfaces/types/storage/TAuth.type";
//import { ClevertapService } from "../clevertap/clevertap.service";
import { AnalyticsService } from "../analytics/analytics.service";
import { environment } from "src/environments/environment";
import { getTagsConfigClicked, getTagsConfigRouters } from "./tagsConfig";
import { TEventNames } from "./EventsListPropierties/TagsEventNames";

//type CurrentPlatform = 'browser' | 'native'  | 'ios'  | 'android';


@Injectable({
  providedIn: "root",
})
export class TagsService {
  private platformUser: any;
  //private _currentPlatform: CurrentPlatform;

  constructor(
    private firestore: AngularFirestore,
    public datepipe: DatePipe,
    private storageService: StorageService,
    //private clevertapService: ClevertapService,
    private analyticsService: AnalyticsService,
    public platform: Platform
  ) { }

  private setCurrentPlatform() {
    if (this.platform.is('ios')) {
      return 'ios';
    } else if (this.platform.is('android')) {
      return 'android';
    } else if (this.platform.is('mobileweb')) {
      return 'pwa';
    } else if (this.platform.is('desktop')) {
      return 'web';
    } else {
      //fallback to browser APIs or
      console.log('The platform is not supported');
    }
  }

  async setEventTag(
    page: TPage,
    event: TEvent | TEventNames,
    analyctics: boolean = false,
    clevertap: boolean = false,
    auth?: TAuth,
    category?: string
  ) {
    let user: IGnxClaims = await this.storageService.get("gnx-claims");
    //console.log(`event:::: -->${page} -->${event} -->${auth} -->${category}`)
    if (!user) {
      user = this.userUndefined();
    }
    this.saveTagInfo(page, event, user);
    if (analyctics) {
      this.analyticsService.trackEvent(event);
    }
    // if (clevertap) {
    //   this.clevertapService.pushEvent(event);
    // }
  }
  private saveTagInfo(
    page: TPage,
    event: TEvent | TEventNames,
    user: IGnxClaims
  ): Promise<any> {
    //console.log("save info tag: ", user, page, program, uid, action);
    var timestamp = new Date().getTime();
    var date = this.datepipe.transform(new Date(), "yyyyMMdd");
    this.platformUser = this.setCurrentPlatform();
    console.log("platform", this.platformUser);
    //console.log("enviroment: ",environment.gluky.app_id);
    //console.log("appName: ",environment.appName);
    //new Date().toLocaleString('en-US', { timeZone: 'Asia/Jakarta' })
    //console.log("date: ",date);
    //console.log("saveTagInfo")
    return this.firestore
      .collection("logs")
      .doc(timestamp.toString())
      .set({
        date: date,
        timestamp: timestamp.toString(),
        page: page,
        event: event,
        username: user.username,
        email: (user.email) ? user.email : user.phone,
        uid: user.uid,
        platform: this.platformUser,
        app_id: environment.gluky.app_id,
        instance: environment.appName
      })
      .catch((err) => {
        console.log(err);
      });
  }

  private setClevertap(user) { }

  private setAnalytics(user) { }
  private userUndefined() {
    let user = {
      app_id: "",
      email: "",
      email_verified: false,
      phone: "",
      phone_verified: false,
      profile: "",
      programs: [""],
      roles: "",
      uid: "",
      username: "",
    };
    return user;
  }

  saveTagInfoPublic(
    page: TPage,
    event: TEvent,
    user: String,
    info: String
  ): Promise<any> {
    var timestamp = new Date().getTime();
    var date = this.datepipe.transform(new Date(), "yyyyMMdd");
    this.platformUser = this.setCurrentPlatform();

    return this.firestore
      .collection("logs")
      .doc(timestamp.toString())
      .set({
        date: date,
        timestamp: timestamp.toString(),
        page: page,
        event: event,
        username: user,
        email: user,
        uid: '',
        platform: this.platformUser,
        app_id: environment.gluky.app_id,
        instance: environment.appName,
        info: info
      })
      .catch((err) => {
        console.log(err);
      });
  }

  public setTagFromEvent(eventKey: TEventNames, isEventPage = false) {
    if (eventKey) {
      const configTags = isEventPage ? getTagsConfigRouters(eventKey) : getTagsConfigClicked(eventKey);
      console.debug('TagsConfig', configTags);
      if (configTags) {
        this.setEventTag(configTags.page, configTags.EventAnalitycs, configTags.analitycs, configTags.clevertap).then(() => {
          console.log('Set tags success', configTags.EventAnalitycs);
        }).catch(error => {
          console.error('Set tags error', error);
        })
      } else {

      }
    }
  }


}