import { Injectable } from "@angular/core";
import { StorageService } from "../storage/storage.service";
import { AngularFirestore } from "@angular/fire/firestore";
import { AuthService } from "../auth/auth.service";
import firebase from "firebase/app";
import { UserService } from "src/app/services/user/user.service";
import { UtilsService } from "../utils/utils.service";

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  uid: string;
  constructor(
    private storageService: StorageService,
    private firestoreA: AngularFirestore,
    private authService: AuthService,
    private userService: UserService,
    private utilsService: UtilsService
  ) { }

  getAdress() { }

  async getUidUser() {
    try {
      const claims = await this.storageService.get("gnx-claims");
      const token = await this.storageService.get("gnx-token");
      const userInfo = this.utilsService.parseJwt(token);
      this.uid = userInfo.gluky.uid || claims.uid;
      console.log("this.uid: ", this.uid);
      return this.uid;
    } catch (error) {
      console.log("error getUid user", error);
    }
  }

  async setAdress(newAdresss) {
    var docId = this.uid != null ? this.uid : await this.getUidUser();
    this.firestoreA
      .collection("user-info")
      .doc(docId)
      .update({
        address: firebase.firestore.FieldValue.arrayUnion(newAdresss),
      });
  }

  getAllAdress() {
    //return await this.storageService.get("adress");
    var docId = this.uid != null ? this.uid : this.authService.userGnxId;
    return this.firestoreA
      .collection("user-info")
      .doc(docId)
      .valueChanges();
  }
  
  async setActiveAdresss(adress) {
    var docId = this.uid != null ? this.uid : await this.getUidUser();
    this.firestoreA
      .collection("user-info")
      .doc(docId)
      .update({
        activeAddress: adress,
      });

    await this.storageService.set("active-address", adress);
  }

  async getActiveAdress() {
    return await this.storageService.get("active-address");
  }

  removeAdress(address) {
    var docId = this.uid != null ? this.uid : this.authService.userGnxId;
    this.firestoreA
      .collection("user-info")
      .doc(docId)
      .update({
        address: firebase.firestore.FieldValue.arrayRemove(address),
      });
    this.unsetActiveAdress(address);
  }

  async unsetActiveAdress(address) {
    var docId = this.uid != null ? this.uid : await this.getUidUser();
    this.firestoreA
      .collection("user-info")
      .doc(docId)
      .update({
        activeAddress: firebase.firestore.FieldValue.arrayRemove(address),
      })
  }
}
