import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { KpiService } from 'src/app/services/kpi/kpi.service';
import { LanguagesService } from 'src/app/services/languagesService/languages.service';

@Component({
  selector: 'app-kpi-carousel',
  templateUrl: './kpi-carousel.component.html',
  styleUrls: ['./kpi-carousel.component.scss'],
})
export class KpiCarouselComponent implements OnInit {

  sliderw = 1.5;
  @Input() titleCarousel: string;
  @Input() subtitleCarousel: string;
  @Input() user;
  @Output() kpiListSelected: EventEmitter<any> = new EventEmitter();
  @Output() kpiSelected: EventEmitter<any> = new EventEmitter();

  cant = 5;
  slideOpts = {
    slidesPerView: window.screen.width > 600 ? 4 : 2.2,
    freeMode: true,
    coverflowEffect: {
      rotate: 50,
      stretch: 2,
      depth: 100,
      modifier: 0,
      slideShadows: true,
    },
  };
  list;


  constructor(private kpiService: KpiService,
    private languageService: LanguagesService) { }

  ngOnInit() {
    this.getKpis();
  }

  slidePrev() {
    const slides = document.querySelectorAll('ion-slides')[1];
    slides.slidePrev();
  }

  slideNext() {
    const slides = document.querySelectorAll('ion-slides')[1];
    slides.slideNext();
  }
  
  async getKpis() {
    this.list = await this.kpiService.getKpis(this.user,this.cant);
    console.log("this.list",this.list);
  }

  kpiListHandler(){
    this.kpiListSelected.emit();
  }

  kpiDetail(data){
    this.kpiSelected.emit(data);
  }

  get getTextsIUPageKpi() {
    return this.languageService.textsIU.page_kpi;
  }



}
