import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'exhibitionStatus'
})
export class ExhibitionStatusPipe implements PipeTransform {

  transform(value: string, ...args) {
     let textStatus:String = "";
     let colorStatus:String = "";
     let nameClass:String = "";
     switch (parseInt(value)) {
      case 1:
        colorStatus = "warning";
        textStatus = "Pendiente";
        nameClass = "alert-circle-outline";
        break;
      case 2:
        colorStatus = "success";
        textStatus = "Aceptada";
        nameClass = "checkmark-circle-outline";
        break;
      case 3:
        colorStatus = "danger";
        textStatus = "Rechazado";
        nameClass = "close-circle-outline";
        break;
      default: //Unsync
        colorStatus = "light";
        textStatus = "No sincronizada";
        nameClass = "sync-circle-outline";
        break;
     }

    if(args && args.length > 0 && args[0] == "color"){
      return colorStatus;
    }
    if(args && args.length > 0 && args[0] == "name"){
      return nameClass;
    }

    return textStatus;
  }

}
