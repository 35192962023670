import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from "@angular/common/http";
import { AuthService } from "../auth/auth.service";

import { catchError, delay, mergeMap, retryWhen, tap, switchMap } from "rxjs/operators";
import { from, of, throwError } from "rxjs";
import { CODES_TO_RETRY, RETRIES, RETRY_WAIT_MILLI_SECONDS } from "src/app/services/interceptor/config-police-retry-http";
import { Observable, timer } from 'rxjs';
import { StorageService } from "../storage/storage.service";
import { RouterService } from '../router/router.service';

@Injectable({
  providedIn: "root",
})

export class ServerHttpInterceptor implements HttpInterceptor {
  retryDelay = 2000;
  retryMaxAttempts = 1;

  constructor(private authService: AuthService, private storageService: StorageService, private routerService: RouterService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): any {
    /* When user get new refresh token with X-Skip-Interceptor
    this code prevent infinite loop in this interceptor
    */
    if (request.headers.get("X-Skip-Interceptor") == "register") {
      return next.handle(request)
    }

    if (request.headers.get("X-Skip-Interceptor") == "") {
      return next.handle(request)
        .pipe(
          this.retryAfterDelay(),
        );
    }

    return from(this.authService.refreshToken()).pipe(
      switchMap((token) => {
        let headers = {};
        /* IF TOKEN EXIST USE ON REQUEST */
        if (token) {
          headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          };
        }
        /*REQUEST INSIDE TABS (INSIDE APLICACION WITH USER AUTHENTICATED */
        if (request.headers.get("X-Skip-Interceptor") !== "") {
          request = request.clone({
            setParams: {
              key: this.authService.apiKey,
            },
          });
        }
        /* ADD HEADERS */
        request = request.clone({
          setHeaders: headers,
        });

        return next.handle(request)
          .pipe(
            this.retryAfterDelay(),
          );
      })
    )
  }

  retryAfterDelay(): any {
    return retryWhen(errors => {
      return errors.pipe(
        mergeMap((err, count) => {
          // throw error when we've retried ${retryMaxAttempts} number of times and still get an error
          console.log("count error", count);
          console.log("count error X2", err, err.error);
          if (count === this.retryMaxAttempts) {
            if ((err && err.error && err.error.code == 'ACCESS_DENIED')) {
              localStorage.clear();
              this.storageService.removeAll();
              this.routerService.navigate("login", { replaceUrl: true });
            }
            return throwError(err);
          }
          return of(err).pipe(
            tap(error => console.log(`Retrying ${error.url}. Retry count ${count + 1}`)),
            mergeMap(() => timer(this.retryDelay))
          );
        })
      );
    });
  }

}