import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements OnInit {

  @Input() image: any;
  zoomProperties = {
    "double-tap": true, // double tap to zoom in and out.
    "overflow": "hidden",// Am not sure. But Documentation says, it will not render elements outside the container.
    "wheel": true, // Disables mouse - To enable scrolling. Else mouse scrolling will be used to zoom in and out on web.
    "disableZoomControl": "never", // stops showing zoom + and zoom - images.
    "backgroundColor": "rgba(0,0,0,0)",
    "draggableUnzoomed": true, // Makes the pinch zoom container color to transparent. So that ionic themes can be applied without issues.
   }
  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {}

  dismiss(){
    this.modalController.dismiss(null, null, "modal-img")

  }

  
}
