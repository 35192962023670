import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imageThanks'
})
export class ImageThanksPipe implements PipeTransform {

  transform(language: string): string {
    const URL = "../../../../assets/img/thanks/";
    return `${URL}${language}.png`;
  }
}
