// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  appName: 'Essilor app',
  gmApiKey: 'AIzaSyAhKdTC6kvQG5hfOGeKheKbsWBo1ZtkTXc',
  firebaseConfig: {
    apiKey: 'AIzaSyDJLncATsGo86Y4OM4zeRo2kMBPLm1RYhk',
    authDomain: 'essilor-app-gluky.firebaseapp.com',
    databaseURL: 'https://essilor-app-gluky.firebaseio.com',
    projectId: 'essilor-app-gluky',
    storageBucket: 'essilor-app-gluky.appspot.com',
    messagingSenderId: '19144723272',
    appId: '1:19144723272:web:5b50f1f697fe7430b6417d',
    measurementId: 'G-4YQ1YT0LHN',
  },
  gluky: {
    app_id: 'gnx_essilor',
    programId: 'essilor_experts_asesor',
    companyId: 'essilor_colombia_sas',
    apikey: 'AIzaSyAQdWB4_5DmOR6DsIMAzgRekj6WlOXNBec',
    apikeyPwa: 'AIzaSyAQdWB4_5DmOR6DsIMAzgRekj6WlOXNBec',
    apiKeyWeb: 'AIzaSyAQdWB4_5DmOR6DsIMAzgRekj6WlOXNBec',
    apiKeyAndroid: 'AIzaSyAQdWB4_5DmOR6DsIMAzgRekj6WlOXNBec',
    usersApiBasePath: 'https://users-api.glukynet.com/v2',
    ordersApiBasePatch: 'https://orders-api.glukynet.com/v1',
    pointsApiBasePath: 'https://bank-api.glukynet.com/v1',
    bffApiBasePath: 'https://bff-api.cms.glukynet.com/v1',
    productsApiBasePath: 'https://products-front-api.glukynet.com/v1',
    catalogueApiBasePath:
      'https://fb07883f71224ce39a631c312f9e280b.ent-search.us-east1.gcp.elastic-cloud.com',
    challengesStrapiApiBasePath: "https://bff-api.cms.glukynet.com/v1/contents/challenges",
    kpiFrontApiBasePath: "https://kpis-front-us-east1-21cvo6vg.ue.gateway.dev/v1",
    productsUrl: "https://products-front-api.glukynet.com/v1",
    cloudinaryUrl: 'https://res.cloudinary.com/glukygroup/',
    bffApiBasePathV2: "https://bffv2-cms-test.glukynet.com/v2",
    cmsBffApiBasePath: "https://bffv3-cms.glukynet.com/v3"
  },
  country: 'mx',
  img_profile_default:
    'https://firebasestorage.googleapis.com/v0/b/gnx-mvp.appspot.com/o/defaults%2Fdefault-user.jpg?alt=media&token=15fbfa41-6ba2-4a88-8530-e5e5f41e45a4',
  phoneIndicator: '+57',
  clevertapAccountId: 'RK6-K58-RW6Z',
  pathNotification: 'https://notifications-api.glukynet.com/v1',
  dinamycLink: {
    url: 'https://mvpgnx.page.link/1Crm',
    packageName: 'com.gluky.essilor.experts',
  },
  certificates: [
    { pais: 'CO', certificate: 'certificates_co' },
    { pais: 'MX', certificate: 'certificates_mx' },
  ],
  pageSize: 50,
  maxPageSize: 50,
  geochallenges: {
    proximityRadiusInMeters: 800
  },
  wall: {
    prefixCollections: 'essilor',
    programMode: 'program',
    pixoEditorConfig: {
      apiKey: 'l5yf4gr2wn40',
      theme: 'wordpress',
      height: '95%',
      width: '95%',
      overlay: 'rgba(209, 209, 209)',
      logosrc: 'none',
      features: ['adjustments', 'filters', 'draw', 'crop', 'stickers', 'text', 'transform', 'frame', 'blur', 'shape'],
      stickers: [
        {
          // This is the PNG image that will be inserted. The larger size the better.
          // Required. The URL must be publicly accessible or CORS-friendly.
          src: 'https://i.pravatar.cc/150?img=3',

          // This is the thumbnail image that will be shown in the property panel.
          // Optional. If missing "src" will be used. The URL may not be CORS-friendly
          // or publicly accessible.
          thumb: 'https://i.pravatar.cc/100?img=3',

          // Sticker caption that will be printed in the property panel below the image.
          // Required.
          caption: 'My Cool Sticker1',

          // Keywords are not displayed, but are considered when filtering stickers.
          // Optional.
          keywords: ['cool', 'awesome'],
        },
        {
          src: 'https://i.pravatar.cc/150?img=4',
          thumb: 'https://i.pravatar.cc/100?img=4',
          caption: 'My Cool Sticker2',
          keywords: ['cool2', 'awesome2'],
        },
      ],
    }
  },
  emblu: {
    endpoint: "https://us-central1-essilor-app-gluky.cloudfunctions.net/getEmblueToken",
    associatePushToEmail: "https://us-central1-essilor-app-gluky.cloudfunctions.net/setAssociatePushToEmail",
    newContact: "https://us-central1-essilor-app-gluky.cloudfunctions.net/setNewContact"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
