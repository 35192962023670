import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  token = null;
  messageOnBackground$ = new Subject();
  broadcast = new BroadcastChannel('myAppChannel');
  constructor(private afMessaging: AngularFireMessaging) {}
 
  requestPermission() {
    return this.afMessaging.requestToken.pipe(
      tap(token => {
        console.log("set token xxxxx", token);
        return token;
      })
    );
  }
 
  getMessages() {
    return this.afMessaging.messages;
  }
  
  getOnMessages() {
    this.broadcast.onmessage = (event) => {
      this.messageOnBackground$.next();
    }
  }
  deleteToken() {
    if (this.token) {
      this.afMessaging.deleteToken(this.token);
      this.token = null;
    }
  }
}
