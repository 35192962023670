import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
})
export class InputTextComponent implements OnInit {

  @Input() iconName: 'text' | 'number' | 'email';
  @Input() type: string;

  pattern = "[A-Za-z]";

  constructor() { }

  ngOnInit() {
    switch (this.type) {
      case 'text':
        this.pattern = "[a-zA-Z ]*"
        break;
      case 'number':
        this.pattern = "[0-9]"
    }
  }

}
