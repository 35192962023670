import { Pipe, PipeTransform } from '@angular/core';
import { LanguagesService } from '../services/languagesService/languages.service';

@Pipe({
  name: 'parseStates'
})
export class ParseStatesPipe implements PipeTransform {

  constructor(private languageService: LanguagesService) { }

  get getTextIUOrdersStatus() {
    return this.languageService.textsIU["statusOrder"];
  }

  transform(value: unknown, ...args: unknown[]): unknown {
    let state = '';
    let colorState = '';
    switch (value) {
      case 'confirm_received':
        state = this.getTextIUOrdersStatus.confirm_received;
        colorState = 'success';
        break;
      case 'reception_confirmed':
        state = this.getTextIUOrdersStatus.confirm_received;
        colorState = 'secondary';
        break;
      case 'collected':
        state = 'Recogido';
        colorState = 'success';
        break;
      case 'pending':
        state = this.getTextIUOrdersStatus.pending;
        colorState = 'warning';
        break;
      case 'processing':
        state = this.getTextIUOrdersStatus.processing;
        colorState = 'warning';
        break;
      case 'central_xfer':
        state = this.getTextIUOrdersStatus.central_xfer;
        colorState = 'warning';
        break;
      case 'acepted':
        state = this.getTextIUOrdersStatus.acepted;
        colorState = 'secondary';
        break;
      case 'cancelled':
        state = this.getTextIUOrdersStatus.cancelled;
        colorState = 'danger';
        break;
      case 'delivered':
        //Entregado por la transportadora. Si no es enviable como un bono pasa a este estado luego de generado el mismo.
        state = this.getTextIUOrdersStatus.delivered;
        colorState = 'success';
        break;
      case 'delivery_failed':
        //Falló entrega por la transportadora, otros campos tienen los detalles (TODO: cuales)
        state = 'Error en la entrega';
        colorState = 'warning';
        break;
      case 'created':
        state = this.getTextIUOrdersStatus.created;
        colorState = 'warning';
        break;
      case 'retry_central_xfer':
        state = this.getTextIUOrdersStatus.retry_central_xfer;
        colorState = 'danger';
        break;
      case 'new-unenqueued':
        //Nuevo sin encolar a cola de new deliveries
        state = this.getTextIUOrdersStatus.processing;
        colorState = 'danger';
        break;
      case 'new':
        //Nuevo
        state = this.getTextIUOrdersStatus.processing;
        colorState = 'danger';
        break;
      case 'awaiting_approval':
        //Esperando autorización del cliente (ej. Bees)
        state = this.getTextIUOrdersStatus.processing;
        colorState = 'danger';
        break;
      case 'central_transfer':
        //Enviando a central de pedidos
        state = this.getTextIUOrdersStatus.processing;
        colorState = 'danger';
        break;
      case 'process_nequi':
        //Procesando en Nequi, intentando crear transferencia
        state = this.getTextIUOrdersStatus.processing;
        colorState = 'danger';
        break;
      case 'process_quantum':
        //Procesando en Quantum, intentando crear bono
        state = this.getTextIUOrdersStatus.processing;
        colorState = 'danger';
        break;
      case 'refund_pending':
        //Creando transacción de reembolso
        state = this.getTextIUOrdersStatus.processing;
        colorState = 'danger';
        break;
      case 'shipping':
        //Recogido por la transportadora (debe tener transportadora y guía)
        state = this.getTextIUOrdersStatus.processing;
        colorState = 'danger';
        break;
      case 'notified':
        //Cuando manejado por tercero ya fue notificado al tercero (productos que maneja el cliente)
        state = 'Notificado';
        colorState = 'danger';
        break;
      case 'erp_imported':
        //Cargado al Importador ERP
        state = this.getTextIUOrdersStatus.processing;
        colorState = 'danger';
        break;
      case 'erp_created':
        //Creado en el ERP
        state = this.getTextIUOrdersStatus.processing;
        colorState = 'danger';
        break;
      case 'pack_ready':
        //Listo Para Envio
        state = this.getTextIUOrdersStatus.processing;
        colorState = 'danger';
        break;
      case 'dispatch':
        //Recogido, sin ws de rastreo
        state = "Despacho";
        colorState = 'danger';
        break;
      case 'delivery_third':
        //Pedido Tercero
        state = this.getTextIUOrdersStatus.processing;
        colorState = 'danger';
        break;
      case 'delivery_third_reported':
        //Pedido de Tercero Reportado
        state = this.getTextIUOrdersStatus.pending;
        colorState = 'danger';
        break;
      
    }
    if (args && args.length > 0 && args[0] == "color") {
      return colorState;
    }
    return state;
  }

}
