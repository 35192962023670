import { Component, OnInit, Input } from '@angular/core';
import { LanguagesService } from '../../services/languagesService/languages.service';
import { IPageLoginChat } from '../../interfaces/ILangs';
import { ModalController } from '@ionic/angular';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { StorageService } from "src/app/services/storage/storage.service";

@Component({
  selector: 'app-profile-modal',
  templateUrl: './profile-modal.component.html',
  styleUrls: ['./profile-modal.component.scss'],
})
export class ProfileModalComponent implements OnInit {
  userForm: FormGroup;
  dynamicForm = [];
  placeHolders = {};
  @Input() values: { editables: []; user: {} };
  myNumberAuth: string;
  claims: any;

  constructor(
    private formBuilder: FormBuilder,
    private languagesService: LanguagesService,
    private storageService: StorageService,
    private modalController: ModalController
  ) {
    this.userForm = this.formBuilder.group({});
  }

  async ngOnInit() {
    this.fillPlaceHolders();
    this.generateForm();
    this.myNumberAuth = await this.storageService.get("phone-to-register");
    this.claims = await this.storageService.get("gnx-claims");
  }
  async generateForm() {
    await this.values.editables.map((editable: any) => {
      console.log('editable =', editable);
      if (editable.name == "email") {
        this.userForm.addControl(
          editable.name,
          new FormControl('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')])
        );
      } else {
        this.userForm.addControl(
          editable.name,
          new FormControl('', [Validators.required])
        );
      }
    });
  }
  fillPlaceHolders() {
    const placeholders: any = this.languagesService.textsIU.page_login_chat.data;
    console.log(this.values.editables)
    this.values.editables.map((editable: any) => {
      placeholders.map((placeholder) => {
        if (editable.id === placeholder.id) {
          editable.placeholder = placeholder.question;
        }
      });
    });
  }

  handleDate(date: Date): string {
    const newDate = new Date(date);
    const year = newDate.getFullYear();
    const month = newDate.getMonth() + 1;
    const day = newDate.getDate();
    const finalDate = `${day}/${month}/${year}`;
    return finalDate;
  }

  sendForm() {
    const valuesForm = this.userForm.value;

    var data = Object.assign({}, valuesForm);
    // data["dateBorn"] = this.handleDate(data.dateBorn);
    data["email"] = data.email;
    data["phone"] = this.myNumberAuth;
    data["firebaseCredential1"] = this.myNumberAuth;
    data["registerDate"] = new Date();
    data["gnxCredential1"] = this.claims.username;

    this.modalController.dismiss(data);
  }
}