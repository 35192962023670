import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { DesignSystemModule } from 'src/theme/design-system/design-system.module';
import { CommonModule } from "@angular/common";
import { AvatarComponent } from "./avatar/avatar.component";
import { ProfileComponent } from "./profile/profile.component";
import { ContactModalComponent } from "./contact-modal/contact-modal.component";
import { ProfileModalComponent } from "./profile-modal/profile-modal.component";
import { IonicModule } from "@ionic/angular";

import { PipesModule } from "../pipes/pipes.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { HeaderComponent } from "./header/header.component";
import { BottomSheetComponent } from "./bottom-sheet/bottom-sheet.component";
import { CheckoutStatusComponent } from "./checkout-status/checkout-status.component";
import { CardContentComponent } from "./card-content/card-content.component";
import { CardContentSkeletonComponent } from "./card-content/skeleton/card-content-skeleton/card-content-skeleton.component";
import { RouterModule } from "@angular/router";
import { PageNotFoundComponent } from "./page-notfound/page-not-found/page-not-found.component";
import { SlideExhibitionsComponent } from "./slide-exhibitions/slide-exhibitions.component";
import { IconProfileComponent } from "./svg/icon-profile/icon-profile.component";
import { IconPointsComponent } from "./svg/icon-points/icon-points.component";
import { IconAddressComponent } from "./svg/icon-address/icon-address.component";
import { IconOrdersComponent } from "./svg/icon-orders/icon-orders.component";
import { IconConfigurationComponent } from "./svg/icon-configuration/icon-configuration.component";
import { MenuComponent } from "./menu/menu.component";
import { CatalogueDetailComponent } from "./catalogue-detail/catalogue-detail.component";
import { NgxCaptchaModule } from "ngx-captcha";
import { HistoryPointsComponent } from "./history-points/history-points.component";
import { CameraComponent } from "./camera/camera.component";
import { LivechatWidgetModule } from "@livechat/angular-widget";
import { ModalAlertsComponent } from './modal-alerts/modal-alerts.component';
import { PushNotificationsManagerComponent } from './push-notifications-manager/push-notifications-manager.component';
import { PromptComponent } from './prompt/prompt.component';
import { HeaderLoginComponent } from "./header-login/header-login.component";
import { ItemContentComponent } from "./item-content/item-content.component";
import { ImageComponent } from "./image/image.component";
import { YouTubePlayerModule} from '@angular/youtube-player';
import { PinchZoomModule } from 'ngx-pinch-zoom';

@NgModule({
  declarations: [
    AvatarComponent,
    ProfileComponent,
    ContactModalComponent,
    ProfileModalComponent,
    HeaderComponent,
    PushNotificationsManagerComponent,
    BottomSheetComponent,
    CheckoutStatusComponent,
    CardContentComponent,
    CardContentSkeletonComponent,
    PageNotFoundComponent,
    SlideExhibitionsComponent,
    IconProfileComponent,
    IconPointsComponent,
    IconAddressComponent,
    IconOrdersComponent,
    IconConfigurationComponent,
    MenuComponent,
    CatalogueDetailComponent,
    HistoryPointsComponent,
    CameraComponent,
    ModalAlertsComponent,
    HeaderLoginComponent,
    ItemContentComponent,
    ImageComponent,
    PromptComponent
  ],
  exports: [
    AvatarComponent,
    ProfileComponent,
    ContactModalComponent,
    ProfileModalComponent,
    HeaderComponent,
    PushNotificationsManagerComponent,
    BottomSheetComponent,
    CheckoutStatusComponent,
    CardContentComponent,
    CardContentSkeletonComponent,
    PageNotFoundComponent,
    SlideExhibitionsComponent,
    IconProfileComponent,
    IconPointsComponent,
    IconAddressComponent,
    IconOrdersComponent,
    IconConfigurationComponent,
    MenuComponent,
    CatalogueDetailComponent,
    HistoryPointsComponent,
    CameraComponent,
    ModalAlertsComponent,
    HeaderLoginComponent,
    ItemContentComponent,
    ImageComponent,
    PinchZoomModule,
    PromptComponent
  ],
  imports: [
    LivechatWidgetModule,
    CommonModule,
    IonicModule,
    RouterModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    DesignSystemModule,
    YouTubePlayerModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsModule { }
