import { Injectable } from "@angular/core";
import { PointsService } from "../points/points.service";
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { alertController } from '@ionic/core';
import { LanguagesService } from "src/app/services/languagesService/languages.service";
import { RemoteConfigService } from "src/app/services/remote-config/remote-config.service";
import { StorageService } from "../storage/storage.service";

@Injectable({
  providedIn: "root",
})
export class UtilsService {
  points: any;
  multiples_accounts: boolean = false;

  constructor(private pointsService: PointsService,
    private db: AngularFirestore,
    public languageService: LanguagesService,
    public remoteConfig: RemoteConfigService,
    private storageService: StorageService) { }

  getUserPoints() {
    return this.pointsService.getUserPoints().then((result: any) => {
      return this.points = (result && result.data && result.data[0] && result.data[0].balance && result.data[0].balance.points) ? result.data[0].balance.points : '0';
    });
  }

  getUserHistoryPoints(accountId?) {
    return this.pointsService
      .getUserHistoryPoints(accountId)
      .then((result) => {
        return result;
      });
  }

  getUserTypeAccount() {
    return this.pointsService.getUserTypeAccount().then((result) => {
      return result;
    });
  }

  getCollectionInfo(collection, id) {
    return this.db.collection(collection).doc(id).valueChanges();
  }

  async getMultiplesAccounts() {
    return this.remoteConfig.getMultiplesAccounts().subscribe((res) => {
      this.multiples_accounts = res;
    });
  }

  //validation multiples account
  async selectUsername(dataUsers): Promise<boolean> {
    let resolveFunction: (confirm: boolean) => void;
    const promise = new Promise<boolean>(resolve => {
      resolveFunction = resolve;
    });
    var username;
    var title = (this.languageService.textsIU.page_login_multiple_account.title) ?? 'Selecciona una cuenta.';
    const inputs = dataUsers.map((data, index) => ({
      name: `radio${index}`,
      type: 'radio',
      label: data,
      value: data,
      handler: () => {
        username = data;
      }
    }));
    const alert = await alertController.create({
      cssClass: 'my-custom-class',
      header: title,
      inputs: inputs,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            resolveFunction(false);
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Enviar',
          handler: async () => {
            console.log('Confirm Ok, username: ' + username);
            await this.storageService.set('username', username);
            resolveFunction(true);
          }
        }
      ]
    });
    await alert.present();
    return promise;
  }

  parseJwt(token) {
    try {
      if (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
      }
    } catch (error) {
      console.log("error parseJwt", error);
    }
  }

}
