import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { LanguagesService } from "src/app/services/languagesService/languages.service";
import { RemoteConfigService } from "src/app/services/remote-config/remote-config.service";
import { isPlatform } from "@ionic/core";
import { Camera, CameraOptions } from "@ionic-native/camera";
import { ModalController } from "@ionic/angular";
import { CameraComponent } from "../../camera/camera.component";
import { InvoicesService } from "src/app/services/invoices/invoices.service";
import { finalize } from "rxjs/operators";
import firebase from "firebase";
import { StorageService } from "src/app/services/storage/storage.service";
import { AngularFireStorage } from "@angular/fire/storage";
import { LoaderService } from "src/app/services/loader/loader.service";
import { AlertService } from "src/app/services/alert/alert.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-invoice-form-component",
  templateUrl: "./invoice-form.component.html",
  styleUrls: ["./invoice-form.component.scss"],
})
export class InvoiceFormComponent implements OnInit, OnDestroy {
  fields = [];
  invoiceForm: FormGroup;
  fileName = "";
  formProps;
  LOADER_ID = "invoice-form";
  inputProps: {
    name: string;
    onChange: (value) => void;
    onBlur: () => void;
    onFocus: () => void;
  };

  constructor(
    public languageService: LanguagesService,
    private remoteConfig: RemoteConfigService,
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private invoicesService: InvoicesService,
    private storageServices: StorageService,
    private storage: AngularFireStorage,
    private loaderService: LoaderService,
    private alertService: AlertService,
    private router: Router
  ) {
    this.invoiceForm = this.formBuilder.group({});
  }

  async ngOnInit() {
    await this.loaderService.cancelLoader(this.LOADER_ID);
    this.invoiceForm = this.formBuilder.group({});
    this.generateForm();
    this.invoiceForm.reset()
  }

  inputCameraProps = (field) => {
    const name = field.name;
    return {
      name,
      id: name,
      onChange: this.handleCameraChange(name),
      onBlur: this.handleBlur(name),
      onFocus: this.handleFocus(name),
      value: this.f[name].value,
    };
  };

  handleCameraChange = (field: string) => (value: string) => {
    this.uploadFile(value, field);
    this.f[field].markAsDirty();
    this.f[field].markAsTouched();
  };

  handleBlur = (field: string) => () => {
    this.f[field].markAsDirty();
  };

  handleFocus = (field: string) => () => {
    this.f[field].markAsTouched();
  };

  get getTextsIUPageInvoices() {
    return this.languageService.textsIU.page_invoices_form;
  }

  get f() {
    return this.invoiceForm.controls;
  }

  async generateForm() {
    const fieldsData = await this.remoteConfig
      .getSettingsInvoicesConfig()
      .toPromise();
    this.fields = fieldsData.fields;
    let validations = [];
    this.fields.map(async (field) => {
      validations = [];
      field.validations?.includes("required")
        ? validations.push(Validators.required)
        : "";
      field.validations?.includes("min")
        ? validations.push(
            Validators.minLength(field.validations.split(":")[0])
          )
        : "";
      field.validations?.includes("max")
        ? validations.push(
            Validators.maxLength(field.validations.split(":")[0])
          )
        : "";
      await this.invoiceForm.addControl(
        field.name,
        field.subfields
          ? this.formBuilder.array([], validations)
          : this.formBuilder.control("", validations)
      );
      if (field.type === "camera") {
        const formField = {
          name: field.name,
          value: field.value,
          validations: "min:0",
        };
        this.inputProps = this.inputCameraProps(formField) as any;
      }
    });
  }

  getArray(name) {
    const controlArray = this.invoiceForm.controls[name] as FormArray;
    return controlArray.controls;
  }

  addField(control) {
    const controlArray = this.invoiceForm.controls[control.name] as FormArray;
    if (controlArray.length == 10) {
      return this.alertService.presentAlert("El maximo de productos son 10");
    }
    let arrayForm = this.formBuilder.group({});
    let validations = [];
    control.subfields.map(async (field) => {
      validations = [];
      field.validations?.includes("required")
        ? validations.push(Validators.required)
        : "";
      field.validations?.includes("min")
        ? validations.push(
            Validators.minLength(field.validations.split(":")[0])
          )
        : "";
      field.validations?.includes("max")
        ? validations.push(
            Validators.maxLength(field.validations.split(":")[0])
          )
        : "";
      await arrayForm.addControl(
        field.name,
        this.formBuilder.control("", validations)
      );
    });
    controlArray.push(arrayForm);
  }

  deleteField(control, controlIndex: number) {
    const controlArray = this.invoiceForm.controls[control] as FormArray;
    controlArray.removeAt(controlIndex);
  }

  async uploadFile(file, field) {
    await this.loaderService.presentLoading('FILEUP');
    const claims = await this.storageServices.get("gnx-claims");
    const ext = /[.]/.exec(file.name) ? /[^.]+$/.exec(file.name).pop() : "png";
    const filePath = `users/${
      claims.uid
    }/invoice/${this.invoicesService.newId()}.${ext}`;
    if (file.name) {
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, file);
      task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            const downloadURL = fileRef.getDownloadURL();
            downloadURL.subscribe(async (url) => {
              if (url) {
                this.f[field].setValue(url);
                await this.loaderService.cancelLoader('FILEUP');
              }
            });
          })
        )
        .subscribe((url) => {
          if (url) {
            console.log(url)
          }
        });
        await this.loaderService.cancelLoader('FILEUP');
    } else {
      const uploadFile = await firebase
        .storage()
        .ref()
        .child(filePath)
        .putString(file, "data_url");
      const url = await uploadFile.ref.getDownloadURL();
      this.f[field].setValue(url);
      await this.loaderService.cancelLoader('FILEUP');
      return url;
    }
  }

  async onFileSelected(event, field) {
    await this.loaderService.presentLoading('FILE')
    const file: File = event.target.files[0];
    if (file) {
      this.fileName = file.name;
      await this.uploadFile(file, field);
      await this.loaderService.cancelLoader('FILE');
      await this.loaderService.cancelLoader('FILEUP');
    }
  }

  async openCamera() {
    if (isPlatform("hybrid")) {
      this.inputProps.onFocus();
      const options: CameraOptions = {
        destinationType: Camera.DestinationType.DATA_URL,
        encodingType: Camera.EncodingType.JPEG,
        mediaType: Camera.MediaType.PICTURE,
        allowEdit: false,
        sourceType: Camera.PictureSourceType.CAMERA,
        correctOrientation: true,
      };
      Camera.getPicture(options)
        .then((imageData) => {
          let base64Image = "data:image/jpeg;base64," + imageData;
          this.inputProps.onChange(base64Image);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.inputProps.onBlur();
        });
    } else {
      const modal = await this.modalController.create({
        component: CameraComponent,
        componentProps: {
          inputProps: this.inputProps,
          onClose: async () => {
            await modal.dismiss({ dismissed: true });
          },
        },
      });
      await modal.present();
    }
  }

  cancel() {
    this.invoiceForm.reset();
    this.fileName = "";
    this.router.navigateByUrl("app/invoices");
  }

  async sendForm() {
    try {
      await this.loaderService.presentLoading(this.LOADER_ID);
      const claims = await this.storageServices.get("gnx-claims");
      console.log('f ==',this.f);
      const initialData = {
        invoice: {
          id: this.f["id"].value,
          id_emisor: this.f["id_emisor"].value,
        },
        uid: claims.uid,
        timestamp: new Date(),
        status: "in-progress",
      };
      if (this.f["date"]) {
        this.f["date"].setValue(new Date(this.f["date"].value));
      }
      if (this.f["image"] && this.f["photo"]) {
        if (this.f["image"].value == "" && this.f["photo"].value == "") {
          await this.loaderService.cancelLoader(this.LOADER_ID);
          return this.alertService.presentAlert(
            "Debes agregar una foto o un archivo"
          );
        }
      }
      const fixedFields = ["id_emisor", "date", "total", "photo", "image", "id"]
      let fields = [];
      Object.keys(this.invoiceForm.controls).forEach(key => {
        if(!fixedFields.includes(key)){
          fields.push({
            name: key,
            value: this.f[key].value
          })
          this.f[key].reset();
          this.invoiceForm.removeControl(key);
        }
      });

      this.f["id_emisor"].reset();
      this.invoiceForm.removeControl("id_emisor");

      const data = { 'fields': fields, ...initialData, ...this.invoiceForm.value };

      this.fileName = "";
      this.invoiceForm.reset();

      if(this.f["camera"]){
        this.f["camera"].setValue("");
      }

      this.invoicesService.saveInvoice(data, claims.uid);
      this.ngOnInit();
      this.alertService.presentAlert("Información guardada exitosamente");
      await this.loaderService.cancelLoader(this.LOADER_ID);
      this.router.navigateByUrl("app/invoices");
    } catch (error) {
      await this.loaderService.cancelLoader(this.LOADER_ID);
      console.log('error ==', error);
      this.alertService.showErrorAlert(`Error, ${error}`);
    }
  }

  ngOnDestroy() {
    console.log('Items destroyed');
  }
}
