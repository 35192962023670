import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class ItemComponent implements OnInit {
  @Input() title : string;
  @Input() subTitle : string;
  @Input() date : Date;
  @Input() points : number;
  @Input() pointsName : string;
  @Input() detail : string;
  @Input() detail2 : string;
  viewDetails = false;
  constructor() { }

  ngOnInit() {}

}
