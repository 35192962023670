import { Component, Input, OnInit } from '@angular/core';
import { RemoteConfigService } from 'src/app/services/remote-config/remote-config.service';
import { RouterService } from 'src/app/services/router/router.service';

@Component({
  selector: 'app-header-login',
  templateUrl: './header-login.component.html',
  styleUrls: ['./header-login.component.scss'],
})
export class HeaderLoginComponent implements OnInit {

  @Input() title: string;
  @Input() page: string; 
  apiWhatsapp: string = "https://api.whatsapp.com/send?phone=";
  phoneContact: any;
  
  constructor(private routerService: RouterService,
    public remoteConfig: RemoteConfigService,
    ) { }

  async ngOnInit() {
    this.phoneContact = await this.remoteConfig.getPhoneContact()["co"];
    this.apiWhatsapp = `${this.apiWhatsapp}${this.phoneContact}&text=¡Hola!`;
  }

  goBack(page){
    this.routerService.navigate(page);
  }

  openChat(){
    window.location.href = this.apiWhatsapp;
  }

}
