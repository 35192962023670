import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { Circles } from '../../../../app/utils/circles.js';
import { Donut } from '../../../../app/utils/gauge3.min.js';
import { LanguagesService } from 'src/app/services/languagesService/languages.service';

declare global {
  interface Window {
    Gauge: any;
  }
}
@Component({
  selector: 'app-kpi-gauge',
  templateUrl: './kpi-gauge.component.html',
  styleUrls: ['./kpi-gauge.component.scss'],
})
export class KpiGaugeComponent implements OnInit, AfterViewInit {
  @ViewChild('gnxKpiCanvas') canvas: ElementRef; 
  @ViewChild('gnxValueInsight') valueInsight: ElementRef; 
  
  opt;
  
  advance: number;
  goal: number;
  value_porcent: any;
  btnText = '';

  @Input() info;
  @Input() source;
  @Input() gaugeId;
  @Input() gaugeValue;
  @Input() button: boolean = false;
  @Input() radius: number = 60;
  @Input() btnDetail: string = '';
  @Input() mode: "insight" | "circle" | "tachometer" | "list" | "data";
  @Output() kpiSelected: EventEmitter<any> = new EventEmitter();

  constructor(private languageService: LanguagesService) { }

  ngOnInit() {
  }

  ngAfterViewInit(){
    console.log("ngAfterViewInit");
    this.loadKpi();
  }

  kpiHandler(data){
    this.kpiSelected.emit(data);
  }

  loadKpi() {
    var styles = getComputedStyle(document.documentElement);
    var value = String(styles.getPropertyValue('--ion-color-primary')).trim();
    switch (this.mode) {
      case "insight":
        var opts = {
          angle: 0.03, // The span of the gauge arc
          lineWidth: 0.09, // The line thickness
          radiusScale: 1, // Relative radius
          pointer: {
            length: 0.45, // // Relative to gauge radius
            strokeWidth: 0.035, // The thickness
            color: '#000000' // Fill color
          },
          limitMax: false,     // If false, max value increases automatically if value > maxValue
          limitMin: false,     // If true, the min value of the gauge will be fixed
          colorStart: (value != '') ? value : '#4065fa',   // Colors
          colorStop: (value != '') ? value : '#4065fa',    // just experiment with them
          strokeColor: '#EEEEEE',  // to see which ones work best for you
          generateGradient: true,
          highDpiSupport: true,     // High resolution support
        };
        this.value_porcent = (this.gaugeValue && this.gaugeValue != 0 && this.gaugeValue != '0') ? this.gaugeValue : 0.1 ;
        //var target = document.getElementById(this.gaugeId); // your canvas element
        var target = this.canvas.nativeElement;
        var gauge = new Donut(target).setOptions(opts); // create sexy gauge!
        gauge.maxValue = 100; // set max gauge value
        gauge.setMinValue(0);  // Prefer setter over gauge.minValue = 0
        gauge.animationSpeed = 32; // set animation speed (32 is default value)
        gauge.set(this.value_porcent); // set actual value
        gauge.setTextField(document.getElementById(this.gaugeId + '_value'));
        break;
      case "list":
        var optsList = {
          angle: 0.04, // The span of the gauge arc
          lineWidth: 0.09, // The line thickness
          radiusScale: 1, // Relative radius
          pointer: {
            length: 0.45, // // Relative to gauge radius
            strokeWidth: 0.035, // The thickness 
            color: '#000000' // Fill color
          },
          limitMax: false,     // If false, max value increases automatically if value > maxValue
          limitMin: false,     // If true, the min value of the gauge will be fixed
          colorStart: (value != '') ? value : '#4065fa',   // Colors
          colorStop: (value != '') ? value : '#4065fa',    // just experiment with them
          strokeColor: '#EEEEEE',  // to see which ones work best for you
          generateGradient: true,
          highDpiSupport: true,     // High resolution support
        };
        console.log("this.gaugeValue ", this.gaugeValue);
        this.value_porcent = (this.gaugeValue && this.gaugeValue != 0 && this.gaugeValue != '0') ? this.gaugeValue : 0.1 ;
        var targetL = this.canvas.nativeElement;
        //var targetL = document.getElementById(this.gaugeId); // your canvas element
        var gaugeL = new Donut(targetL).setOptions(optsList); // create sexy gauge!
        gaugeL.maxValue = 100; // set max gauge value
        gaugeL.setMinValue(0);  // Prefer setter over gauge.minValue = 0
        gaugeL.animationSpeed = 32; // set animation speed (32 is default value)
        gaugeL.set(this.value_porcent); // set actual value
        gaugeL.setTextField(document.getElementById(this.gaugeId + '_value'));
        break;
      case "data":
        var optsData = {
          angle: 0.04, // The span of the gauge arc
          lineWidth: 0.09, // The line thickness
          radiusScale: 1, // Relative radius
          pointer: {
            length: 0.45, // // Relative to gauge radius
            strokeWidth: 0.035, // The thickness
            color: '#000000' // Fill color
          },
          limitMax: false,     // If false, max value increases automatically if value > maxValue
          limitMin: false,     // If true, the min value of the gauge will be fixed
          colorStart: (value != '') ? value : '#4065fa',   // Colors
          colorStop: (value != '') ? value : '#4065fa',    // just experiment with them
          strokeColor: '#EEEEEE',  // to see which ones work best for you
          generateGradient: true,
          highDpiSupport: true,     // High resolution support
        };
        var porcent = Math.round((this.advance  / this.goal ) * 100) ;
        var value_porcent = (porcent) ? porcent : 0.1 ;
        console.log("porcent", porcent);
        var targetD = document.getElementById(this.gaugeId); // your canvas element
        var gaugeD = new Donut(targetD).setOptions(optsData); // create sexy gauge!
        gaugeD.maxValue = 100; // set max gauge value
        gaugeD.setMinValue(0);  // Prefer setter over gauge.minValue = 0
        gaugeD.animationSpeed = 32; // set animation speed (32 is default value)
        gaugeD.set(value_porcent); // set actual value
        gaugeD.setTextField(document.getElementById(this.gaugeId + '_value'));
        break;
      case "tachometer":
        try {
          var optsT = {
            angle: -0.2, // The span of the gauge arc
            lineWidth: 0.2, // The line thickness
            radiusScale: 1, // Relative radius
            pointer: {
              length: 0.6, // // Relative to gauge radius
              strokeWidth: 0.035, // The thickness
              color: '#000000' // Fill color
            },
            limitMax: false,     // If false, max value increases automatically if value > maxValue
            limitMin: false,     // If true, the min value of the gauge will be fixed
            colorStart: (value != '') ? value : '#4065fa',   // Colors
            colorStop: (value != '') ? value : '#4065fa',    // just experiment with them
            strokeColor: '#E0E0E0',  // to see which ones work best for you
            generateGradient: true,
            highDpiSupport: true,     // High resolution support

          };
          var targetT = document.getElementById(this.gaugeId); // your canvas element
          var gauget = new window.Gauge(targetT).setOptions(optsT); // create sexy gauge!
          gauget.maxValue = 100; // set max gauge value
          gauget.setMinValue(0);  // Prefer setter over gauge.minValue = 0
          gauget.animationSpeed = 32; // set animation speed (32 is default value)
          gauget.set(this.gaugeValue); // set actual value
          gauget.setTextField(document.getElementById(this.gaugeId + '_value'));
        } catch (error) {
          console.error("error gague tachomertr", error);
        }
        break;
      case "circle":
        Circles.create({
          id: this.gaugeId,
          radius: this.radius,
          value: this.gaugeValue,
          maxValue: 100,
          width: 10,
          text: function (value) { return value + '%'; },
          colors: ['#EEEEEE', (value != '') ? value : '#4065fa'],
          duration: 400,
          wrpClass: 'circles-wrp',
          textClass: 'circles-text',
          styleWrapper: true,
          styleText: true
        })
        break;
      default:
        break;
    }

  }

  get getTextsIUPageKpi() {
     console.log("his.languageService.textsIU.page_kpi",this.languageService.textsIU.page_kpi);
     return this.languageService.textsIU.page_kpi; 
  }
}
