
export const routerTagsKeys = {
    '/app/home': 'home',
    '/app/kpi/categories': 'kpiCategories',
    '/app/pay-services-automatic': 'bill_paymen_automatic_category',
    '/app/payment-services': 'bill_paymen_manual_category',
    '/app/payment-terminal': 'pointz_dataphones_category',
    '/app/payment-terminal-key': 'pointz_dataphones_password',
    '/recognitions': 'recognitions_category',
    '/app/recognitions': 'recognitions_category',
    ///
    /// onboarding
    'onboarding_start_cliked': 'onboarding_start_cliked',
    '/login': 'login_screen_viewed',
    '/app/profile': 'profile_screen_viewed',
    '/profile/profile-detail': 'profile_detail_screen_viewed',
    '/profile/orders': 'orders_screen_viewed',
    '/app/orders': 'orders_screen_viewed',
    '/app/orders-success': 'advice_screen_viewed',
    'app/app-key-termminal-success': 'advice_screen_viewed',
    //order detail
    'orders_detail_screen_viewed': 'orders_detail_screen_viewed',
    '/app/map/list': 'adress_screen_viewed',
    '/app/profile/history-points': 'points_screen_viewed',
    '/app/profile/config': 'profile_config_screen_viewed',
    '/app/content': 'content_screen_viewed',
    //'/app/kpi': 'indicators_screen_viewed', -> new flow kpi
    '/app/kpi': 'kpiCategories', 
    '/app/catalogue': 'rewards_screen_viewed',
    //catalogue detail
    'rewards_category_detail_screen_viewed': 'rewards_category_detail_screen_viewed',
    '/app/challenges': 'challenge_screen_viewed',
    '/app/exhibitions': 'exhibition_screen_viewed',
    '/app/invoices': 'ticket_screen_viewed',

}

// NOTE: For details pages is set Tags in the OnInit function of the component
export const tags = {
    home: {
        page: 'home_page',
        EventAnalitycs: 'home_screen_viewed',
        analitycs: true
    },
    kpiCategories: {
        page: 'tabs_menu',
        EventAnalitycs: 'tabs_indicators_categories_clicked',
        analitycs: true
    },
    bill_paymen_automatic_category: {
        page: 'tabs_menu',
        EventAnalitycs: 'bill_paymenautomatic_category',
        analitycs: true
    },
    pointz_dataphones_category: {
        page: 'pointz_dataphones',
        EventAnalitycs: 'pointz_dataphones_category',
        analitycs: true,
        clevertap: false
    },
    pointz_dataphones_password: {
        page: 'pointz_dataphones',
        EventAnalitycs: 'pointz_dataphones_password',
        analitycs: true
    },
    bill_paymen_manual_category: {
        page: 'bill_paymenmanual',
        EventAnalitycs: 'bill_paymen_manual_category',
        analitycs: true,
        clevertap: false
    },
    recognitions_category: {
        page: 'recognitions',
        EventAnalitycs: 'recognitions_category',
        analitycs: true,
        clevertap: false
    },
   /*  home_screen_viewed: {
        page: '',
        EventAnalitycs: 'home_screen_viewed',
        analitycs: true,
        cleverlao: false
    }, */

    onboarding_start_cliked: {
        page: 'onboarding',
        EventAnalitycs: 'onboarding_start_cliked',
        analitycs: true,
        cleverlap: false
    },
    login_screen_viewed: {
        page: 'login_page',
        EventAnalitycs: 'login_screen_viewed',
        analitycs: true,
        cleverlao: false
    },
    profile_screen_viewed: {
        page: 'profile_page',
        EventAnalitycs: 'profile_screen_viewed',
        analitycs: true,
        cleverlao: false
    },
    profile_detail_screen_viewed: {
        page: 'profile_detail_page',
        EventAnalitycs: 'profile_detail_screen_viewed',
        analitycs: true,
        cleverlao: false
    },
    orders_screen_viewed: {
        page: 'orders_page',
        EventAnalitycs: 'orders_screen_viewed',
        analitycs: true,
        cleverlao: false
    },
    orders_detail_screen_viewed: {
        page: 'orders_detail_page',
        EventAnalitycs: 'orders_detail_screen_viewed',
        analitycs: true,
        cleverlao: false
    },
    adress_screen_viewed: {
        page: 'adress_page',
        EventAnalitycs: 'adress_screen_viewed',
        analitycs: true,
        cleverlao: false
    },
    points_screen_viewed: {
        page: 'points_page',
        EventAnalitycs: 'points_screen_viewed',
        analitycs: true,
        cleverlao: false
    },
    profile_config_screen_viewed: {
        page: 'profile_config_page',
        EventAnalitycs: 'profile_config_screen_viewed',
        analitycs: true,
        cleverlao: false
    },
    content_screen_viewed: {
        page: 'content_page',
        EventAnalitycs: 'content_screen_viewed',
        analitycs: true,
        cleverlao: false
    },
    // Deprecated -> set new flow
   /*  indicators_screen_viewed: {
        page: 'indicators_page',
        EventAnalitycs: 'indicators_screen_viewed',
        analitycs: true,
        cleverlao: false
    }, */
    rewards_screen_viewed: {
        page: 'rewards_page',
        EventAnalitycs: 'rewards_screen_viewed',
        analitycs: true,
        cleverlao: false
    },
    rewards_category_detail_screen_viewed: {
        page: 'rewards_detail_page',
        EventAnalitycs: 'rewards_category_detail_screen_viewed',
        analitycs: true,
        cleverlao: false
    },
    advice_screen_viewed: {
        page: 'advice_page',
        EventAnalitycs: 'advice_screen_viewed',
        analitycs: true,
        cleverlao: false
    },
    challenge_screen_viewed: {
        page: 'challenge_page',
        EventAnalitycs: 'challenge_screen_viewed',
        analitycs: true,
        cleverlao: false
    },
    exhibition_screen_viewed: {
        page: 'exhibition_page',
        EventAnalitycs: 'exhibition_screen_viewed',
        analitycs: true,
        cleverlao: false
    },
    ticket_screen_viewed: {
        page: 'ticket_page',
        EventAnalitycs: 'ticket_screen_viewed',
        analitycs: true,
        cleverlao: false
    },
};
