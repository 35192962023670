import { Injectable } from "@angular/core";
import jwt_decode from "jwt-decode";
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: "root",
})
export class StorageService {
  // private _storage: Storage | null = null;
  constructor(private storage: Storage) {
    // this.init();
  }

  // async init() {
  //   const storage = await this.storage.create();
  //   this.storage = storage;
  // }

  async set(key: string, value: any): Promise<void> {
    if (key === 'gnx-token') {
      const decodedToken: any = jwt_decode(value);
      console.log(decodedToken);
      await this.storage.set('gnx-claims', JSON.stringify(decodedToken.gluky));
    }
    await this.storage.set(
      key,
      JSON.stringify(value),
    );
  }

  async get(key: string): Promise<any> {
    const item = await this.storage.get(key);
    return JSON.parse(item);
  }

  async remove(key: string): Promise<void> {
    await this.storage.remove(key);
  }

  async removeAll() {
    await this.storage.clear();
  }
}
