import { DecimalPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import moment from 'moment';
import { RedemptionService } from 'src/app/services/redemption/redemption.service';
import { RouterService } from 'src/app/services/router/router.service';
import { LanguagesService } from 'src/app/services/languagesService/languages.service';

@Component({
  selector: 'app-modal-alerts',
  templateUrl: './modal-alerts.component.html',
  styleUrls: ['./modal-alerts.component.scss'],
  providers: [DecimalPipe]
})
export class ModalAlertsComponent implements OnInit {

  @Input() fromCatalogueDetail = false;
  redemptionInfo;
  msjRedemption = "";
  iconRedemption = "";

  constructor(private modalController: ModalController,
    private redemptionService: RedemptionService,
    private router: RouterService,
    private route: Router,
    private languageService: LanguagesService,

    private numberPipe: DecimalPipe
  ) { }

  async ngOnInit() {
    this.redemptionService.IsOpen = true;
    this.redemptionInfo = await this.redemptionService.getProgramInfo();
    let close = true;
    const now = new Date();
    const minDate = new Date(this.redemptionInfo["redemption_limit"]?.min_date);
    const maxDate = new Date(this.redemptionInfo["redemption_limit"]?.max_date);
    if (this.redemptionInfo["redemption_limit"].min_date) {
      this.msjRedemption = `
        Puedes redimir a partir del
        <br>
        <a>${moment(this.redemptionInfo["redemption_limit"].min_date).format("DD/MM/YYYY h:mm:ss a")}</a>`;
      this.iconRedemption = "../../../assets/icons/home/calendar.svg";
      if (now < minDate) {
        close = false;
        return;
      }
    }

    if (this.redemptionInfo["redemption_limit"].max_date) {
      this.msjRedemption = `
        La redención estuvo activa hasta el 
        <br>
        <a>${moment(this.redemptionInfo["redemption_limit"].max_date).format("DD/MM/YYYY h:mm:ss a")}</a>`;
      this.iconRedemption = "../../../assets/icons/home/calendar.svg";
      if (now > maxDate) {
        close = false;
        return;
      }
    }

    if (!close) return;

    if (this.redemptionInfo["redemption_limit"].min_user_points_limit) {
      this.msjRedemption = `
        Recuerda que la redención tiene que superar los
        <a>${this.numberPipe.transform(Number(this.redemptionInfo["redemption_limit"].min_user_points_limit))}</a> ${this.getTextIUCurrency.title}`;
      this.iconRedemption = "../../../assets/icons/home/money.svg";
      return;
    }

    if (close && this.fromCatalogueDetail) {
      return this.dismiss();
    }
  }

  dismiss() {
    this.modalController.dismiss().then(() => {
      this.redemptionService.IsOpen = false;
    });
    const url = this.route.url;
    if (url != "/app/home") {
      this.router.redirect("app/catalogue");
    }
  }
  get getTextIUCurrency() {
    return this.languageService.textsIU.currency_data;
  }
}
