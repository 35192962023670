import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ComponentsComponent } from './components/components.component';


const routes: Routes = [
  { path: 'design-system', redirectTo: 'components', pathMatch: 'full' },
  {
    path: 'components',
    component: ComponentsComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DesignSystemRoutingModule { }
