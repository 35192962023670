import { filter, pairwise } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Router, NavigationExtras, RoutesRecognized } from "@angular/router";
import { ModalController, NavController, Platform } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class RouterService {
  modalWeb: HTMLIonModalElement;
  private previousUrl
  private currentUrl

  constructor(public router: Router,
    private navCtrl: NavController,
    private platform: Platform,
    public modalController: ModalController,
  ) {
    this.router.events
      .pipe(filter((e: any) => e instanceof RoutesRecognized),
        pairwise()
      ).subscribe((e: any) => {
        this.previousUrl = e[0].urlAfterRedirects
        this.currentUrl = e[1].urlAfterRedirects
      });
  }

  redirect(route: any, modalComponent?: any, modalData?: any, modalClass?: any, modalComponentTwo?: any, id?: any) {
    if (modalComponent && this.platform.is("desktop")) {
      this.openPopup(modalComponent, modalClass, modalData, modalComponentTwo, id);
    } else {
      this.router.navigate([route], { replaceUrl: false });
    }

  }
  async openPopup(component: any, cssClass: any, data?: any, componentToOpen?: any, id?: any) {
    if (this.router.url === "/login") {
      return;
    }
    const modalsOpen = await this.modalController.getTop();
    if (modalsOpen) {
      await modalsOpen.dismiss();
    }
    this.modalWeb = await this.modalController.create({
      component: component,
      cssClass: cssClass,
      componentProps: data,
      showBackdrop: true,
      id: id
    });
    if (componentToOpen) {
      this.openSecondPopup(this.modalWeb, componentToOpen);
    }
    this.modalWeb.onDidDismiss().then(() => {
      document.getElementById(id).remove();
    });
    return await this.modalWeb.present();

  }
  navigate(link: string, extras?: NavigationExtras) {
    this.router.navigate([link], extras);
  }
  redirectRootWithdata(route: string, data: any) {
    this.navCtrl.navigateRoot([route], {
      queryParams: { data: JSON.stringify(data) },
    });
  }
  redirectRoot(route: any, modalComponent?: any, modalData?: any, modalClass?: any) {
    console.log(this.platform.platforms());
    this.modalController.dismiss();
    if (modalComponent && (this.platform.is("desktop") || this.platform.is("mobileweb") || this.platform.is("pwa"))) {
      this.openPopup(modalComponent, modalClass, modalData);
    } else {
      this.navCtrl.navigateRoot([route]);
    }
  }
  redirectWithdata(route: string, data: any, modalComponent?: any, modalData?: any, modalClass?: any) {
    this.modalController.dismiss();
    if (modalComponent && (this.platform.is("desktop") || this.platform.is("mobileweb") || this.platform.is("pwa"))) {
      this.openPopup(modalComponent, modalClass, modalData);
    } else {
      this.router.navigate([route], {
        queryParams: { data: JSON.stringify(data) },
      });
    }
  }
  redirectBack() {
    this.navCtrl.back();
  }

  openSecondPopup(modal: any, component: any) {
    modal.onDidDismiss().then(() => {
      this.openPopup(component, "gnx-modal-radius");
    });
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }
  public getCurrentUrl() {
    return this.currentUrl ? this.currentUrl : this.router.url
  }
}
