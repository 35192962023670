import { Component, OnInit, Input } from "@angular/core";
//Services
import { CameraService } from "src/app/services/camera-service/camera.service";
import { FirebaseStorageService } from "src/app/services/firebase-storage/firebase-storage.service";
import { UserService } from "src/app/services/user/user.service";
import { RemoteConfigService } from "src/app/services/remote-config/remote-config.service";
import { AlertController } from '@ionic/angular';

export const KEY_AVATAR_PROFILE = "KEY_AVATAR_PROFILE";
@Component({
  selector: "app-avatar",
  templateUrl: "./avatar.component.html",
  styleUrls: ["./avatar.component.scss"],
})
export class AvatarComponent implements OnInit {
  @Input() shape: string = "false";
  @Input() edit: string = "true";
  @Input() size: "small" | "medium" | "large" = "medium";
  @Input() shadow: string = "true";
  img: any = "../../../assets/img/default-user.jpg";
  LOADER_ID = "change_picture";
  hideGamification = false;

  constructor(
    private cameraService: CameraService,
    private firebaseStorageService: FirebaseStorageService,
    private userService: UserService,
    public remoteConfig: RemoteConfigService,
    public alertController: AlertController
  ) {}

  ngOnInit() {
    this.getPicture();
    this.getHideGamification();
  }

  async takePicture() {
    try {
      const picture = await this.cameraService.takePicture();
      const urlImage = await this.firebaseStorageService.saveOnStorage(picture);
      const saveImage = await this.userService.savePictureOnfirestore(urlImage);
      await this.getPicture();
    } catch (err) {
      console.log(err);
      this.presentAlert('El proceso no se realizó. Intente nuevamente.');
      throw "no upload file";
    }
  }

  async getPicture() {
    this.userService.getUserInfo().subscribe(
      (res: any) => {
        if (res && res.urlAvatar) {
          this.img = res.urlAvatar;
        }
      },
      (err) => {
        throw err
      }
    );
  }

  async getHideGamification() {
    return this.remoteConfig.getHideGamification().subscribe((res) => {
      this.hideGamification = res;
    });
  }

  async presentAlert(message: string) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Atención',
      message: message,
      buttons: ['OK'],
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }
}
