import { RedemptionService } from 'src/app/services/redemption/redemption.service';
import { PushNotificationsManagerService } from './../push-notifications-manager/push-notifications-manager.service';
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { RemoteConfigService } from "src/app/services/remote-config/remote-config.service";
import { TagsService } from "src/app/services/tags/tags.service";
import { Plugins } from '@capacitor/core';
import { PushNotification } from '@capacitor/push-notifications'
const { PushNotifications } = Plugins;
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @Input() page: string;
  @Input() image: string;
  @Input() title: string;
  @Input() enableMenu: boolean = false;
  @Input() enableChat: boolean = true;
  @Input() enableLine: boolean = false;
  @Input() enableHref: boolean = true;
  @Input() enableRedemption: boolean = false;
  @Input() set isRedemptionActive(value) {
    this.isActive = value;
    this.iconLockRedemption = value ? '../../../assets/icons/home/open_lock.svg' : '../../../assets/icons/home/close_lock.svg';
  };
  @Input() noButtons: boolean = false;
  @Output() onBack: EventEmitter<any> = new EventEmitter();
  isActive = false;
  phoneContact: string;
  apiWhatsapp: string = 'https://api.whatsapp.com/send?phone='
  iconLockRedemption = '';
  notificationUnViewed: string | number = 0
  constructor(
    private tagsService: TagsService,
    private remoteConfig: RemoteConfigService,
    private modalController: ModalController,
    private redemptionService: RedemptionService,
    private pushNotificationsManagerService: PushNotificationsManagerService
  ) {
  }

  ngOnInit() {
    this.pushNotificationsManagerService.numberViewed$.subscribe((element) => {
      this.notificationUnViewed = element;
      console.log('element', element);
    })

    this.phoneContact = this.remoteConfig.getPhoneContact()["co"];
    this.apiWhatsapp = `${this.apiWhatsapp}${this.phoneContact}&text=¡Hola!`;
    if (!this.page) return console.log("back");
  }

  support_chat() {
    this.tagsService.setEventTag("home_page", "support_clicked", true, true);
  }

  back() {
    this.onBack.emit();
  }

  async openRedemptionInfo() {
    if (this.isActive) return;
    this.redemptionService.openModal('header');
  }
}
