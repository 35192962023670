import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

import { Plugins } from "@capacitor/core";
import { Router, RouterEvent, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";


const { FirebaseAnalytics, Device } = Plugins;

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  platform;

  constructor(
    private router: Router,
  ) { }
  async initAnalytics() {
    // if ((await Device.getInfo()).platform == "web") {
    //   FirebaseAnalytics.initializeFirebase(environment.firebaseConfig);
    // }
    // FirebaseAnalytics.setCollectionEnabled({
    //   enabled: true,
    // });

    // this.router.events
    //   .pipe(filter((e: RouterEvent) => e instanceof NavigationEnd))
    //   .subscribe((e: RouterEvent) => {
    //     //console.log("route changed: ", e.url);
    //     this.trackpageViewed(e.url);
    //     //this.clevertapService.pushScreenView(e.url);
    //   });
  }

  setUser(userId) {
    // FirebaseAnalytics.setUserId({
    //   userId,
    // });
  }
  trackEvent(event) {
    // FirebaseAnalytics.logEvent({ name: event });
  }

  trackpageViewed(page) {
    // FirebaseAnalytics.setScreenName({
    //   screenName: page,
    // });
  }
}
