import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CredentialsService } from "src/app/services/credentials/credentials.service";
import { AddressService } from "src/app/services/address/address.service";
import { RouterService } from "src/app/services/router/router.service";
import { StorageService } from "src/app/services/storage/storage.service";
import { UserService } from "src/app/services/user/user.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { LoaderService } from "src/app/services/loader/loader.service";
import { AlertService } from "src/app/services/alert/alert.service";
import { RemoteConfigService } from "src/app/services/remote-config/remote-config.service";
import { TagsService } from "src/app/services/tags/tags.service";
import { LanguagesService } from "../../../services/languagesService/languages.service";

@Component({
  selector: "app-checkout",
  templateUrl: "./checkout.page.html",
  styleUrls: ["./checkout.page.scss"],
})
export class CheckoutPage implements OnInit {
  @Input() productId;
  @Input() desdeModal = false;
  public product;
  public activeAdress: any;
  public show = false;
  public LOADER_ID = "checkout";
  public OrderStatus: CustomEvent;
  public userInfo = {};
  country: string;

  constructor(
    private route: ActivatedRoute,
    private credentialsService: CredentialsService,
    private adressService: AddressService,
    private routerService: RouterService,
    private storageService: StorageService,
    public userService: UserService,
    public authService: AuthService,
    public loaderService: LoaderService,
    public alertService: AlertService,
    public remoteConfigService: RemoteConfigService,
    private tagsService: TagsService,
    private languageService: LanguagesService
  ) {}

  ngOnInit() {
    this.getActiveAdress();
    this.getUserInfo();
    this.route.queryParams.subscribe((params) => {
      this.getActiveAdress();
      this.product = this.productId ? this.productId : JSON.parse(params.data);
    });
  }

  ionViewWillEnter() {
    this.getActiveAdress();
    this.route.queryParams.subscribe((params) => {
      this.product = this.productId ? this.productId : JSON.parse(params.data);
    });
  }

  credentialsOrders = () => {
    return this.credentialsService.orders();
  };

  getActiveAdress = async () => {
    this.activeAdress = { test: "test" }; //temporal data
    (await this.adressService.getAllAdress()).subscribe((info: any) => {
      this.activeAdress = info.activeAddress;
      if (
        info.activeAddress.postalCode.code &&
        info.activeAddress.postalCode.code.length
      ) {
        return;
      }
      this.activeAdress.postalCode.code = "";
    });
  };

  changeAdress = () => {
    this.alertService.dismissPopup();
    this.storageService.set("product-checkout", this.product);
    this.routerService.redirect("map/checkout");
  };

  async getUserInfo() {
    this.remoteConfigService
      .getInfoApp()
      .subscribe((res: any) => (this.country = res.country));
    await this.loaderService.presentLoading(this.LOADER_ID);
    const claims = await this.storageService.get("gnx-claims");
    this.userService.getUserInfo().subscribe(
      async (res: any) => {
        const userFirebase = await this.authService.getUSerInfoFromFirebase();
        this.userInfo = {
          name: res.name,
          lastname: res.lastnames,
          docId: res.gnxCredential1,
          email: userFirebase.email || res.email,
          phone: res.phone.replace("+57", ""),
          //phone: userFirebase.phoneNumber != undefined ? userFirebase.phoneNumber.replace("+57", "") || userFirebase.phoneNumber.replace("57", "") || res.phone : '',
          phone2: "",
          username: claims.username,
          rfc: res.rfc,
        };
        console.log(this.userInfo);
        
        this.show = true;
        await this.loaderService.cancelLoader(this.LOADER_ID);
      },
      async (err) => {
        await this.loaderService.cancelLoader(this.LOADER_ID);
        this.routerService.redirect("app/home");
      }
    );
  }

  async statusOrderhandler(orderStatus: CustomEvent) {
    if (orderStatus.detail.status === "incomplete_form") {
      await this.alertService.presentOrderStatus(
        "Llena todos tus datos en el formulario",
        "close-circle-outline",
        "gnx-icon-order-status-error"
      );
      setTimeout(() => {
        this.alertService.alertController.dismiss();
      }, 2000);
    } else {
      if (orderStatus.detail.status === "order_success") {
        await this.alertService.presentOrderStatus(
          "Tu Orden fue creada correctamente",
          "checkmark-circle",
          "gnx-icon-order-status-success"
        );
        setTimeout(() => {
          this.alertService.alertController.dismiss();
          this.alertService.dismissPopup();
          this.tagsService.setEventTag(
            "rewards_category_confirm",
            "rewards_category_confirm_clicked",
            false,
            true
          );
          return this.routerService.redirect("app/orders-success");
        }, 2000);
      }
      if (orderStatus.detail.status === "order_loading") {
        return await this.alertService.presentOrderStatus(
          "Estamos creando tu orden",
          "cloud-upload-outline",
          "gnx-icon-order-status-loading"
        );
      }
      if (orderStatus.detail.status === "order_error") {
        if (orderStatus?.detail?.response?.code === "INSUFFICIENT_POINTS") {
          await this.alertService.presentOrderStatus(
            "No tienes puntos suficientes",
            "close-circle-outline",
            "gnx-icon-order-status-error"
          );
        } else {
          await this.alertService.presentOrderStatus(
            "Ups, Tenemos problemas para crear tu orden",
            "close-circle-outline",
            "gnx-icon-order-status-error"
          );
        }
        setTimeout(() => {
          return this.alertService.alertController.dismiss();
        }, 2000);
      }
    }
  }
  get getTextIUCurrency() {
    return this.languageService.textsIU.currency_data;
  }
}
