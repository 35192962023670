export const tagsEventProperties = {
    login_page_success: {
        page: 'login_page',
        EventAnalitycs: 'login_page_success',
        analitycs: true,
        clevertap: false
    },
    login_start: {
        page: 'login_page',
        EventAnalitycs: 'login_start',
        analitycs: true,
        clevertap: false
    },
    login_page_error: {
        page: 'login_page',
        EventAnalitycs: 'login_page_error',
        analitycs: true,
        clevertap: false
    },
    register_user_new_flow: {
        page: 'register_extrainfo',
        EventAnalitycs: 'register_user_new_flow',
        analitycs: true,
        clevertap: false
    },
    password_recovery_otp_phone: {
        page: 'password_recovery',
        EventAnalitycs: 'password_recovery_otp_phone',
        analitycs: true,
        clevertap: false
    },
    password_recovery_email: {
        page: 'password_recovery',
        EventAnalitycs: 'password_recovery_email',
        analitycs: true,
        clevertap: false
    },
    Banner: {
        page: 'home_menu',
        EventAnalitycs: 'menu_profile_clicked',
        analitycs: true,
        clevertap: false
    },
    rewards_category_redeem_clicked_shopping_cart: {
        page: 'rewards_detail_page',
        EventAnalitycs: 'rewards_category_redeem_clicked_shopping_cart',
        analitycs: true,
        clevertap: false
    },
    rewards_category_redeem_clicked_more_products: {
        page: 'rewards_detail_page',
        EventAnalitycs: 'rewards_category_redeem_clicked_more_products',
        analitycs: true,
        clevertap: false
    },
    rewards_category_redeem_clicked_view_cart: {
        page: 'rewards_detail_page',
        EventAnalitycs: 'rewards_category_redeem_clicked_view_cart',
        analitycs: true,
        clevertap: false
    },
    shopping_cart_clicked: {
        page: 'shopping_cart',
        EventAnalitycs: 'shopping_cart_clicked',
        analitycs: true,
        clevertap: false
    },
    shopping_cart_redemption: {
        page: 'shopping_cart',
        EventAnalitycs: 'shopping_cart_redemption',
        analitycs: true,
        clevertap: false
    },
    Check_out: {
        page: 'shopping_cart',
        EventAnalitycs: 'Check_out',
        analitycs: true,
        clevertap: false
    },

    bill_paymen_manual_redemption: {
        page: 'bill_paymenmanual',
        EventAnalitycs: 'bill_paymenmanual_redemption',
        analitycs: true,
        clevertap: false
    },
    bill_paymen_automatic_category: {
        page: 'bill_paymenautomatic',
        EventAnalitycs: 'bill_paymenautomatic_category',
        analitycs: true,
        clevertap: false
    },
    bill_paymen_automatic_banner: {
        page: 'bill_paymenautomatic',
        EventAnalitycs: 'bill_paymenautomatic_banner',
        analitycs: true,
        clevertap: false
    },
    bill_paymen_automatic_categories: {
        page: 'bill_paymenautomatic',
        EventAnalitycs: 'bill_paymenautomatic_categories',
        analitycs: true,
        clevertap: false
    },
    bill_paymen_automatic_redemption: {
        page: 'bill_paymenautomatic',
        EventAnalitycs: 'bill_paymenautomatic_redemption',
        analitycs: true,
        clevertap: false
    },

    pointz_dataphones_banner: {
        page: 'pointz_dataphones',
        EventAnalitycs: 'pointz_dataphones_banner',
        analitycs: true,
        clevertap: false
    },
    pointz_dataphones_calculator: {
        page: 'pointz_dataphones',
        EventAnalitycs: 'pointz_dataphones_calculator',
        analitycs: true,
        clevertap: false
    },
    pointz_dataphones_password: {
        page: 'pointz_dataphones',
        EventAnalitycs: 'pointz_dataphones_password',
        analitycs: true,
        clevertap: false
    },
    pointz_dataphones_shops: {
        page: 'pointz_dataphones',
        EventAnalitycs: 'pointz_dataphones_shops',
        analitycs: true,
        clevertap: false
    },
    pointz_dataphones_password_create: {
        page: 'pointz_dataphones',
        EventAnalitycs: 'pointz_dataphones_password_create',
        analitycs: true,
        clevertap: false
    },

    recognitions_send: {
        page: 'recognitions',
        EventAnalitycs: 'recognitions_send',
        analitycs: true,
        clevertap: false
    },
    notifications_clicked: {
        page: 'notifications',
        EventAnalitycs: 'notifications_clicked',
        analitycs: true,
        clevertap: false
    },

    onboarding_skip_cliked: {
        page: 'onboarding',
        EventAnalitycs: 'onboarding_skip_cliked',
        analitycs: true,
        cleverlap: false
    },
    onboarding_next_cliked: {
        page: 'onboarding',
        EventAnalitycs: 'onboarding_next_cliked',
        analitycs: true,
        cleverlap: false
    },
    onboarging_finish_cliked: {
        page: 'onboarding',
        EventAnalitycs: 'onboarging_finish_cliked',
        analitycs: true,
        cleverlap: false
    },
    support_clicked: {
        page: '',
        EventAnalitycs: 'support_clicked',
        analitycs: true,
        cleverlap: false
    },
    home_content_rewards_clicked: {
        page: '',
        EventAnalitycs: 'home_content_rewards_clicked',
        analitycs: true,
        cleverlap: false
    },
    home_indicators_clicked: {
        page: '',
        EventAnalitycs: 'home_indicators_clicked',
        analitycs: true,
        cleverlap: false
    },
    home_rewards_cliked: {
        page: '',
        EventAnalitycs: 'home_rewards_cliked',
        analitycs: true,
        cleverlap: false
    },
    home_content_clicked: {
        page: '',
        EventAnalitycs: 'home_content_clicked',
        analitycs: true,
        cleverlap: false
    },
    menu_profile_clicked: {
        page: '',
        EventAnalitycs: 'menu_profile_clicked',
        analitycs: true,
        cleverlap: false
    },
    menu_orders_clicked: {
        page: '',
        EventAnalitycs: 'menu_orders_clicked',
        analitycs: true,
        cleverlap: false
    },
    menu_address_clicked: {
        page: '',
        EventAnalitycs: 'menu_address_clicked',
        analitycs: true,
        cleverlap: false
    },
    menu_status_clicked: {
        page: '',
        EventAnalitycs: 'menu_status_clicked',
        analitycs: true,
        cleverlap: false
    },
    menu_privacy_clicked: {
        page: '',
        EventAnalitycs: 'menu_privacy_clicked',
        analitycs: true,
        cleverlap: false
    },
    menu_config_clicked: {
        page: '',
        EventAnalitycs: 'menu_config_clicked',
        analitycs: true,
        cleverlap: false
    },
    menu_terms_clicked: {
        page: '',
        EventAnalitycs: 'menu_terms_clicked',
        analitycs: true,
        cleverlap: false
    },
    menu_logout_clicked: {
        page: '',
        EventAnalitycs: 'menu_logout_clicked',
        analitycs: true,
        cleverlap: false
    },
    tabs_home_clicked: {
        page: '',
        EventAnalitycs: 'tabs_home_clicked',
        analitycs: true,
        cleverlap: false
    },
    tabs_content_clicked: {
        page: '',
        EventAnalitycs: 'tabs_content_clicked',
        analitycs: true,
        cleverlap: false
    },
    tabs_indicators_clicked: {
        page: '',
        EventAnalitycs: 'tabs_indicators_clicked',
        analitycs: true,
        cleverlap: false
    },
    tabs_rewards_clicked: {
        page: '',
        EventAnalitycs: 'tabs_rewards_clicked',
        analitycs: true,
        cleverlap: false
    },
    tabs_wall_clicked: {
        page: '',
        EventAnalitycs: 'tabs_wall_clicked',
        analitycs: true,
        cleverlap: false
    },
    profile_info_clicked: {
        page: '',
        EventAnalitycs: 'profile_info_clicked',
        analitycs: true,
        cleverlap: false
    },
    profile_orders_clicked: {
        page: '',
        EventAnalitycs: 'profile_orders_clicked',
        analitycs: true,
        cleverlap: false
    },
    orders_detail_clicked: {
        page: '',
        EventAnalitycs: 'orders_detail_clicked',
        analitycs: true,
        cleverlap: false
    },
    profile_adress_clicked: {
        page: '',
        EventAnalitycs: 'profile_adress_clicked',
        analitycs: true,
        cleverlap: false
    },
    profile_points_clicked: {
        page: '',
        EventAnalitycs: 'profile_points_clicked',
        analitycs: true,
        cleverlap: false
    },
    profile_config_clicked: {
        page: '',
        EventAnalitycs: 'profile_config_clicked',
        analitycs: true,
        cleverlap: false
    },
    content_category_clicked: {
        page: '',
        EventAnalitycs: 'content_category_clicked',
        analitycs: true,
        cleverlap: false
    },
    tabs_indicators_categories_clicked: {
        page: '',
        EventAnalitycs: 'tabs_indicators_categories_clicked',
        analitycs: true,
        cleverlap: false
    },
    indicators_detail_clicked: {
        page: '',
        EventAnalitycs: 'indicators_detail_clicked',
        analitycs: true,
        cleverlap: false
    },
    rewards_filter_clicked: {
        page: '',
        EventAnalitycs: 'rewards_filter_clicked',
        analitycs: true,
        cleverlap: false
    },
    rewards_category_clicked: {
        page: '',
        EventAnalitycs: 'rewards_category_clicked',
        analitycs: true,
        cleverlap: false
    },
    rewards_category_redeem_clicked: {
        page: '',
        EventAnalitycs: 'rewards_category_redeem_clicked',
        analitycs: true,
        cleverlap: false
    },
    rewards_category_confirm_clicked: {
        page: '',
        EventAnalitycs: 'rewards_category_confirm_clicked',
        analitycs: true,
        cleverlap: false
    },
    advice_orders_clicked: {
        page: '',
        EventAnalitycs: 'advice_orders_clicked',
        analitycs: true,
        cleverlap: false
    },
    advice_rewards_clicked: {
        page: '',
        EventAnalitycs: 'advice_rewards_clicked',
        analitycs: true,
        cleverlap: false
    },
    challenge_category_clicked: {
        page: '',
        EventAnalitycs: 'challenge_category_clicked',
        analitycs: true,
        cleverlap: false
    },
    challenge_category_start_clicked: {
        page: '',
        EventAnalitycs: 'challenge_category_start_clicked',
        analitycs: true,
        cleverlap: false
    },
    challenge_photo_clicked: {
        page: '',
        EventAnalitycs: 'challenge_photo_clicked',
        analitycs: true,
        cleverlap: false
    },
    exhibition_category_clicked: {
        page: '',
        EventAnalitycs: 'exhibition_category_clicked',
        analitycs: true,
        cleverlap: false
    },
    exhibition_photo_clicked: {
        page: '',
        EventAnalitycs: 'exhibition_photo_clicked',
        analitycs: true,
        cleverlap: false
    },
    ticket_category_clicked: {
        page: '',
        EventAnalitycs: 'ticket_category_clicked',
        analitycs: true,
        cleverlap: false
    },
    ticket_photo_clicked: {
        page: '',
        EventAnalitycs: 'ticket_photo_clicked',
        analitycs: true,
        cleverlap: false
    },
};
