import { Component, Input, OnInit } from '@angular/core';
import CameraPhoto, { FACING_MODES } from 'jslib-html5-camera-photo';
import { Platform } from '@ionic/angular';
import { InvoicesService } from 'src/app/services/invoices/invoices.service';

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss'],
})
export class CameraComponent implements OnInit {

  element!: HTMLElement;
  @Input() inputProps: {
    name: string;
    onChange: (value) => void;
    onBlur: () => void;
    onFocus: () => void;
  };
  @Input() onClose: () => void;
  cameraStarted: boolean = false;
  switchMode: boolean = false;
  cameraPhoto: CameraPhoto;
  videoElement!: HTMLVideoElement;

  constructor(public platform: Platform,
    public invoicesService: InvoicesService) {}

  ngOnInit() {
    (el: HTMLVideoElement) => (this.videoElement = el);
    this.inputProps.onFocus();
    let videoCamera = document.getElementById('videoCamera') as HTMLVideoElement;
    this.cameraPhoto = new CameraPhoto(videoCamera);
    this.startCamera();
  }

  get isIos(){
    return this.platform.is("ios");
  }

  async startCamera(idealFacingMode: typeof FACING_MODES.USER | typeof FACING_MODES.ENVIRONMENT = FACING_MODES.ENVIRONMENT) {
    this.cameraPhoto
      .startCamera(idealFacingMode, { width: 640, height: 480 })
      .then(() => {
        this.cameraStarted = true;
          console.log('Camera started !', this.cameraPhoto);
      })
      .catch(error => {
        console.error('Camera not started!', error);
      });
  }

  async changeMode() {
    this.switchMode = !this.switchMode;
    const facingMode = this.switchMode ? FACING_MODES.USER : FACING_MODES.ENVIRONMENT;
    this.startCamera(facingMode);
  }

  async takePhoto() {
    const dataUri = this.cameraPhoto.getDataUri({});
    this.inputProps.onChange(dataUri);
    this.dismissModal();
  }

  async dismissModal() {
    if (this.cameraPhoto.stream) {
      await this.cameraPhoto.stopCamera();
    }
    this.inputProps.onBlur();
    this.onClose();
  }

}
