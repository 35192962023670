import { LanguagesService } from 'src/app/services/languagesService/languages.service';
import { PopoverController } from '@ionic/angular';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.scss'],
})
export class PromptComponent {
  @Input('data') public data: { mobileType: 'ios' | 'android', promptEvent?: any }
  image = '../../../assets/icons/icon-72x72.png'
  constructor(
    private popoverCTRL: PopoverController,
    public languageService: LanguagesService,
  ) { }


  public installPwa(): void {
    this.data.promptEvent.prompt();
    this.close();
  }

  public close() {
    this.popoverCTRL.dismiss();
  }

  get getTextsInfoPwa() {
    return this.languageService.textsIU.infoPWA;
  }
}

