import { Component, OnInit, Input } from "@angular/core";
import { PointsService } from "src/app/services/points/points.service";
import { LanguagesService } from "src/app/services/languagesService/languages.service";
import { element } from "protractor";

@Component({
  selector: "app-history-points-component",
  templateUrl: "./history-points.component.html",
  styleUrls: ["./history-points.component.scss"],
})
export class HistoryPointsComponent implements OnInit {
  date: any;
  loading: boolean = false;
  errorFlag: boolean = false;
  errorMessage: string;
  tab: number;
  data: any;
  headerTabs: string[] = [];
  localeStrings: any;
  pointsSort: any[];
  message_error: string;
  pointsName: String = "puntos";

  infoYear: any = 0;
  infoMonth: any;

  constructor(
    private pointsService: PointsService,
    private languageService: LanguagesService
  ) {}

  async ngOnInit() {
    console.log("wc history");
    this.errorMessage = this.getTextsIUPageHistoryPoints.message_error;
    const { base_url, api_key, token, accountId } =
      await this.pointsService.historyCredentials();
    if (base_url === null) {
      this.loading = false;
      this.errorFlag = true;
      this.errorMessage = this.getTextsIUPageHistoryPoints.message_error;
      return false;
    }
    if (base_url && api_key && token) {
      return this.getHistoryPoints(accountId);
    }
    return this.setErrorMessage();
  }

  setErrorMessage = () => {
    this.errorFlag = true;
    this.errorMessage = this.message_error;
  };
  async getHistoryPoints(accountId) {
    this.loading = true;
    this.errorFlag = false;

    try {
      this.data = await this.pointsService.getUserHistoryPoints(accountId);
      this.pointsSort = this.data
        ? this.data.data.sort(
            (a, b) =>
              new Date(b["createdAt"]).getTime() -
              new Date(a["createdAt"]).getTime()
          )
        : [];
      console.log("pointsSort 1 ==", this.pointsSort);

      //cambio de descripcion de ingles a es
      const descriEs = `Retiro de ${this.pointsName}`;
      this.pointsSort.map(function (dato) {
        if (dato.type === "exchange") {
          dato.description = descriEs;
        }
        return dato;
      });

      //console.log("pointsSort 2 ==", this.pointsSort);

      this.getInfoPoints(this.pointsSort);
      this.loading = false;
    } catch (err) {
      this.errorFlag = true;
      this.errorMessage = "Error";
      this.loading = false;
    }
  }

  getInfoPoints(data) {
    let date = new Date();
    var firstDay = new Date(date.getFullYear(), 0, 1);
    var lastDayMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    var test = 0;
    data.forEach(function (value) {
      if (date > firstDay && date < lastDayMonth) {
        test = test + value.points;
      }
    });
  }
  get getTextsIUPageHistoryPoints() {
    return this.languageService.textsIU.page_history_points;
  }
}
