import { PwaService } from './services/pwa/pwa.service';
import { Component, OnInit } from "@angular/core";
import { MenuController, Platform, NavController } from "@ionic/angular";
import { Plugins } from "@capacitor/core";

import { FirebaseDynamicLinks } from "@ionic-native/firebase-dynamic-links/ngx";

//Services
import { RemoteConfigService } from "./services/remote-config/remote-config.service";

import { ValidateVersionService } from "./services/validateVersion/validate-version.service";
import { RouterService } from "./services/router/router.service";
import { AuthService } from "./services/auth/auth.service";
import { LanguagesService } from "./services/languagesService/languages.service";
import { AnalyticsService } from "./services/analytics/analytics.service";
import { FcmService } from "./services/fcm/fcm.service";
import { AuthGnxService } from "src/app/services/authGnx/auth-gnx.service";
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Event, NavigationEnd } from '@angular/router';

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  settingsDidMount: boolean = false;
  isRouterInvalidNotificationsMenu = true;

  constructor(
    private platform: Platform,
    private remoteConfigService: RemoteConfigService,
    private validateVersion: ValidateVersionService,
    private firebaseDynamicLinks: FirebaseDynamicLinks,
    private routerService: RouterService,
    private authService: AuthService,
    private languageService: LanguagesService,
    private analyticsService: AnalyticsService,
    private menuCtrl: MenuController,
    private fcmService: FcmService,
    private authGnx: AuthGnxService,
    private navCtrl: NavController,
    private PwaService: PwaService,
    private router: Router
  ) {
    this.initializeApp();
    this.authService.setApiKeyByPlatform();
  }

  ngOnInit() {
    this.PwaService.initPwaPrompt();
    try {
      if (!this.languageService.loadedLanguage) {
        this.languageService.loadLanguage();
      }
    } catch (error) {
      console.log("error init", error);
    }
  }

  async initializeApp() {
    this.validateVersion.validate();
    this.listenForDynamicLinks();
    this.platform.ready().then(() => {
      this.analyticsService.initAnalytics();
      this.fcmService.initPush();
    });
    this.settingsDidMount = true;
    this.remoteConfigService.initRemoteConfig();
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.isRouterInvalidNotificationsMenu = (event.url.includes('login')
          || event.url.includes('recover-account')
          || event.url.includes('register')
          || event.url.includes('live-chat')
        )
      }
    })
  }

  listenForDynamicLinks() {
    this.firebaseDynamicLinks.onDynamicLink().subscribe(
      (res: any) => {
        this.authService.getParamsAuth(res.deepLink);
        this.routerService.redirect("auth-confirmation");
      },
      (error: any) => console.log(error)
    );
  }

  getPathDisableMenuNotifications() {
    const paths = ['login', 'register', 'otp', 'recover-account', 'live-chat']; // para inhabilitar el menu en una ruta solo añada la ruta en este array
    let disable = false;
    paths.forEach((data) => {
      if ((this.routerService.getCurrentUrl() as string)?.includes(data)) {
        disable = true;
      }
    })
    return disable;
  }

}
