import { Injectable } from "@angular/core";
import { AngularFireStorage } from "@angular/fire/storage";

import { AuthService } from "../auth/auth.service";
@Injectable({
  providedIn: "root",
})
export class FirebaseStorageService {
  IMG_PATH = "users_profile/";
  constructor(
    private storage: AngularFireStorage,
    private authService: AuthService
  ) { }

  saveOnStorage(data: any) {
    return new Promise(async (resolve, reject) => {
      try {
        const userFirebase = await this.authService.getUSerInfoFromFirebase();
        const ref = this.getRef(userFirebase.uid);
        await ref.putString(data, "data_url");
        ref.getDownloadURL().subscribe(
          (url) => resolve(url),
          (err) => reject(),
        )
      } catch (error) {
        reject({ "status": false, "err": error, "url": '' });
      }
    }).catch(error => {
      // deal any errors
      return new Promise(async (resolve) => {
        resolve({ "status": false, "err": error, "url": '' });
      })
    });
  }

  getRef(uid: string) {
    return this.storage.ref(`${this.IMG_PATH}/${uid}.jpg`);
  }
}
