import { Injectable } from "@angular/core";
// import { Plugins, CameraResultType, CameraSource, CameraOptions } from "@capacitor/core";
import { Camera, CameraResultType, CameraSource, CameraOptions } from '@capacitor/camera';

// const { Camera } = Plugins;
@Injectable({
  providedIn: "root",
})
export class CameraService {
  options: CameraOptions = {
    quality: 70,
    source: CameraSource.Photos,
    allowEditing: false,
    resultType: CameraResultType.DataUrl,

  }
  constructor() { }
  async takePicture() {
    const image = await Camera.getPhoto(this.options);
    const imageUrl = image.dataUrl;
    return imageUrl;
  }
}
