import { ModalAlertsComponent } from 'src/app/components/modal-alerts/modal-alerts.component';
import { ModalController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import {  Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class RedemptionService {
  redemptionInfo
  IsOpen = false;
  constructor(private http: HttpClient,
    private storageServices: StorageService,
    private modalController: ModalController,
  ) { }

  async getProgramInfo(){
    const claims = await this.storageServices.get("gnx-claims");
    const companyId = claims.programs[0].split('/')[1];
    const programId = claims.programs[0].split('/')[3];
    return new Promise((resolve, reject) =>
    this.http
        .get(
          `${environment.gluky.usersApiBasePath}/frontend/companies/${companyId}/programs/${programId}`
        )
        .subscribe(
          async (res) => {
            this.redemptionInfo = res;
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        )
    );
  }

  async validateRedemption(): Promise<boolean> {
    let isActive = true;
    const redemptionInfo = await this.getProgramInfo();
    if (redemptionInfo['redemption_limit']?.enabled) {
      return new Promise((resolve, reject) => {
        const now = new Date();
        const minDate = new Date(redemptionInfo["redemption_limit"]?.min_date);
        const maxDate = new Date(redemptionInfo["redemption_limit"]?.max_date);
        if (redemptionInfo["redemption_limit"].min_date && redemptionInfo["redemption_limit"].max_date) {
          if (now < minDate || now > maxDate) {
            isActive = false;
          } else {
            isActive = true
          }
        }
        if (redemptionInfo["redemption_limit"].min_user_points_limit) {
          isActive = false
        }
        resolve(isActive);
      })
    }
  }

  async checkRedemption(product?, isDetail = false) {
    const redemptionInfo = this.redemptionInfo
      ? this.redemptionInfo
      : await this.getProgramInfo();

    if (redemptionInfo["redemption_limit"]?.enabled) {
      if (redemptionInfo["redemption_limit"]?.min_date && redemptionInfo["redemption_limit"]?.max_date) {
        if (redemptionInfo["redemption_limit"]?.min_date.length > 0 && redemptionInfo["redemption_limit"]?.max_date.length > 0) {
          const now = new Date();
          const minDate = new Date(redemptionInfo["redemption_limit"]?.min_date);
          const maxDate = new Date(redemptionInfo["redemption_limit"]?.max_date);
          if (now < minDate || now > maxDate) {
             this.openModal('service , now < minDate || now > maxDate');
             return;
          }
        }
      }

      if (redemptionInfo["redemption_limit"]?.min_user_points_limit) {
        if (isDetail) {
          if (product.pointz.raw < redemptionInfo["redemption_limit"]?.min_user_points_limit) {
           this.openModal('service , product.pointz.raw < redemptionInfo["redemption_limit"]?.min_user_points_limit');
           return;
          }
        } else {
          this.openModal('service ,else ');
          return;
        }

      }
    }
  }

  async openModal(router?) {
    console.log('open from ', router);
    if (!this.IsOpen) {
      const modal = await this.modalController.create({
        component: ModalAlertsComponent,
        id: "redemption-modal",
        componentProps: {
          fromCatalogueDetail: true,
          onClose: async (data) => {
            await modal.dismiss({ dismissed: true });
          }
        },
        cssClass:'my-custom-height-modal-redemption',
        backdropDismiss:false,
        swipeToClose:false
      });

      modal.onDidDismiss().then(() => {
        this.IsOpen = false
        this.closeAllModalsRedemption();
      })
      return await modal.present().then(() => {     
        this.IsOpen = true
        const el =  document.getElementsByTagName('ion-modal')
        for (let i = 0; i < el.length; i++) {
          const element = el[i];
          if (element.id == 'redemption-modal') {
            element.style.setProperty('--height', '40vh');
             
          } 
          
        }
      });
    }

  }
  closeAllModalsRedemption(){
    const alerts = document.getElementsByTagName('ion-modal')
    for (let i = 0; i < alerts.length; i++) {
      const element = alerts[i];
      if (element.id == 'redemption-modal') {
         element.remove();
      } 
      element.dismiss();
      
    }
  }
}
