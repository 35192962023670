import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { KpiService } from "src/app/services/kpi/kpi.service";
import { Donut } from '../../../../utils/gauge3.min.js';

@Component({
  selector: "app-kpi-detail-component",
  templateUrl: "./kpi-detail.component.html",
  styleUrls: ["./kpi-detail.component.scss"],
})
export class KpiDetailComponent implements OnInit, AfterViewInit {
  opt;
  source;
  gaugeId;
  gaugeValue;
  list;
  detailsGraph;
  indicatorText: any;
  value_porcent: any;

  @Input() langs;
  @Input() user;
  @Input() idgraph;

  constructor(private kpiService: KpiService) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.loadKpi();
  }

  async loadKpi() {
    const data = await this.kpiService.getDetailGraph(this.idgraph);
    this.list = data[0];
    var styles = getComputedStyle(document.documentElement);
    var value = String(styles.getPropertyValue("--ion-color-primary")).trim();
    // this.kpiDetailStatusHandler(res);
    //console.log("this.list",this.list);
    this.indicatorText = this.list && this.list.indicator_name ? this.list.indicator_name : "Meta de ventas";
    var opts = {
      angle: 0.03, // The span of the gauge arc
      lineWidth: 0.09, // The line thickness
      radiusScale: 1, // Relative radius
      pointer: {
        length: 0.45, // // Relative to gauge radius
        strokeWidth: 0.035, // The thickness
        color: "#000000", // Fill color
      },
      limitMax: false, // If false, max value increases automatically if value > maxValue
      limitMin: false, // If true, the min value of the gauge will be fixed
      colorStart: value != "" ? value : "#4065fa", // Colors
      colorStop: value != "" ? value : "#4065fa", // just experiment with them
      strokeColor: "#EEEEEE", // to see which ones work best for you
      generateGradient: true,
      highDpiSupport: true, // High resolution support
    };
    this.value_porcent =
      this.list.current && this.list.current != 0 && this.list.current != "0"
        ? this.list.current
        : 0.1;
    var target = document.getElementById("id_detail_gauge"); // your canvas element
    var gauge = new Donut(target).setOptions(opts); // create sexy gauge!
    gauge.maxValue = 100; // set max gauge value
    gauge.setMinValue(0); // Prefer setter over gauge.minValue = 0
    gauge.animationSpeed = 32; // set animation speed (32 is default value)
    gauge.set(this.value_porcent); // set actual value
    gauge.setTextField(document.getElementById("id_detail_gauge_value"));
  }

}
