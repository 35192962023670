import { PromptComponent } from './../../components/prompt/prompt.component';
import { Injectable } from '@angular/core';
import { Platform, ModalController, PopoverController } from '@ionic/angular';
/* import { timer } from 'rxjs';
import { take, timeout } from 'rxjs/operators'; */


@Injectable({
  providedIn: 'root'
})
export class PwaService {
  private promptEvent: any;
  constructor(
    private platform: Platform,
   // private modalCTRL: ModalController,
    private popoverCTRL: PopoverController,
  ) { }

  public initPwaPrompt() {
    console.log('InitPWaPront');

    if (this.platform.is('android')) {
      console.log('is android');
      window.addEventListener('beforeinstallprompt', (event: any) => {
        event.preventDefault();
        this.promptEvent = event;
        this.openPromptComponent('android');
      });
    }
    if (this.platform.is('ios')) {
      console.log('is ios');
      const isInStandaloneMode = ('standalone' in window.navigator) && (window.navigator['standalone']);
      if (!isInStandaloneMode) {
        this.openPromptComponent('ios');
      }
    }
  }

   openPromptComponent(mobileType: 'ios' | 'android') {
    console.log('openPromptComponent');/* 
     timer(3000)
      .pipe(take(1))
      .subscribe(async () => { 
        console.log('open modal'); */
        setTimeout(()=>{
           this.popoverCTRL.create({
          component: PromptComponent,
          componentProps: { data: { mobileType, promptEvent: this.promptEvent } },
          cssClass:'my-custom-width-popover'
        }).then((data)=>{
          data.present()
        })
        },2000)
       
      /*  }
      );  */
  }
}