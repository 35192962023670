import { RedemptionService } from 'src/app/services/redemption/redemption.service';
import { PushNotificationsManagerService } from './../push-notifications-manager/push-notifications-manager.service';
import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { MenuController, ModalController, Platform } from "@ionic/angular";
//Services
import { RouterService } from "src/app/services/router/router.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { UserService } from "../../services/user/user.service";
import { LanguagesService } from "../../services/languagesService/languages.service";
//Interfaces
import { ISettingsMenu } from "../../interfaces/ISettings";
import { IMenu } from "../../interfaces/ILangs";
import { TagsService } from "src/app/services/tags/tags.service";
import { Event, NavigationEnd, Router } from '@angular/router';
import { RemoteConfigService } from 'src/app/services/remote-config/remote-config.service';

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent implements OnInit {
  showMenu = "my-custom-menu";
  swipeMenu = true;
  type = "overlay";
  LOADER_ID = "menu";
  name: string;
  // texts IU for language
  textsIUMenu: IMenu[];
  settingsMenu: ISettingsMenu[];
  phoneContact: string;
  apiWhatsapp: string = 'https://api.whatsapp.com/send?phone=';
  @Input() points: string = "1";
  widthBody: number = 0;
  @ViewChild("ionMenu") ionMenu: any;
  @Input() enableRedemption: boolean = false;
  @Input() set isRedemptionActive(value) {
    this.isActive = value;
    this.iconLockRedemption = value ? '../../../assets/icons/home/open_lock.svg' : '../../../assets/icons/home/close_lock.svg';
  };
  isActive
  iconLockRedemption
  isRouterValidAlertLimit;
  notificationsNumber = 0;
  notificationUnViewed
  constructor(
    public routerService: RouterService,
    private menuCtr: MenuController,
    private authService: AuthService,
    private userService: UserService,
    public languageService: LanguagesService,
    private tagsService: TagsService,
    private platform: Platform,
    private redemptionService: RedemptionService,
    private router: Router,
    private pushNotificationsManagerService: PushNotificationsManagerService,
    private remoteConfig: RemoteConfigService
  ) { }
  get getTextIUCurrency() {
    return this.languageService.textsIU.currency_data;
  }
  async ngOnInit() {
    this.getInfoUser();

    this.widthBody = document.querySelector("body").clientWidth;
    //@TODO Validación menú para web
    if (this.platform.is("desktop")) {
      this.swipeMenu = false;
      this.showMenu = "my-custom-menu show-menu";
      this.type = "reveal";
    }
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.isRouterValidAlertLimit = (event.url.includes('home') || event.url.includes('catalogue'))
      }
    })
    this.pushNotificationsManagerService.numberViewed$.subscribe((element) => {
      this.notificationUnViewed = element;
      console.log('element', element);

    })
  }
  async onClick() {
    // this.showMenu = "my-custom-menu "
  }

  async support_chat() {
    await this.remoteConfig
      .getInfoApp()
      .toPromise()
      .then((res: any) => {
        this.phoneContact = res['phone-contact']['co'];
        this.apiWhatsapp = `${this.apiWhatsapp}${this.phoneContact}&text=¡Hola!`;
        location.href = this.apiWhatsapp;
      });
  }

  redirectTo(route, name) {
    console.log("route", route)
    this.menuCtr.close();
    this.ionMenu.close();
    this.setTags(name);
    if (!route) {
      this.pushNotificationsManagerService.deleteInfoCache();
      return this.authService.logout();
    }

    this.routerService.redirect(route);
  }

  async getInfoUser() {
    this.userService.getUserInfo().subscribe((res: any) => {
      if (res) {
        this.name = res.name + " " + res.lastnames;
      }
    });
  }
  changeLanguage(language: string) {
    this.languageService.loadLanguage(language);
  }
  get getTextsIUMenu() {
    if (this.widthBody > 575)
      return this.languageService.textsIU.menu_web.filter(
        (item) => item.active
      );
    return this.languageService.textsIU.menu.filter((item) => item.active);
  }

  async openRedemptionInfo() {
    if (this.isActive) return;
    if (!this.redemptionService.IsOpen) return this.redemptionService.openModal('menu');
  }

  setTags(id: number) {
    switch (id) {
      case 1:
        this.tagsService.setEventTag(
          "home_menu",
          "menu_profile_clicked",
          false,
          true
        );
        break;
      case 2:
        this.tagsService.setEventTag(
          "home_menu",
          "menu_orders_clicked",
          false,
          true
        );
        break;
      case 3:
        this.tagsService.setEventTag(
          "home_menu",
          "menu_adress_clicked",
          false,
          true
        );
        break;
      case 4:
        this.tagsService.setEventTag(
          "home_menu",
          "menu_status_clicked",
          false,
          true
        );
        break;
      case 5:
        this.tagsService.setEventTag(
          "home_menu",
          "menu_privacy_clicked",
          false,
          true
        );
        break;
      case 6:
        this.tagsService.setEventTag(
          "home_menu",
          "menu_config_clicked",
          false,
          true
        );
        break;
      case 7:
        this.tagsService.setEventTag(
          "home_menu",
          "menu_terms_clicked",
          false,
          true
        );
        break;
      case 7:
        this.tagsService.setEventTag(
          "home_menu",
          "menu_logout_clicked",
          false,
          true
        );
        break;

      default:
        console.log("no se ha encontrado event tag");
        break;
    }
  }
  setNumber(number) {
    this.notificationsNumber = number
  }
}
