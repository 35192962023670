import { TEvent } from "src/app/interfaces/types/TEvent.type"
import { TPage } from "src/app/interfaces/types/TPage.type"
import { TEventNames } from "./EventsListPropierties/TagsEventNames"
import { routerTagsKeys, tags } from "./EventsListPropierties/tags"
import { tagsEventProperties } from "./EventsListPropierties/tagsEventProperties"


interface Tags {
    page: TPage,
    EventAnalitycs: TEventNames,
    analitycs: boolean,
    clevertap?: boolean

}

export const getTagsConfigRouters = (url): Tags => {
    if (!tags[routerTagsKeys[url]]) {
        console.warn('No tags config for this url', url)
        return;
    }
    return tags[routerTagsKeys[url]]
}

export const getTagsConfigClicked = (event): Tags => {
    if (!tagsEventProperties[event]) {
        console.warn('No tags config for this Event', event)
        return;
    }
    return tagsEventProperties[event]
}

