import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { StorageService } from "../storage/storage.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CatalogueService {
  constructor(
    private http: HttpClient,
    private storageServices: StorageService
  ) {}

  async sendToken() {
    const claims = await this.storageServices.get("gnx-claims");

    let postData = {
      appUserId: claims.uid,
      program: claims.programs[0],
    };

    return new Promise((resolve, reject) =>
      this.http
        .post(`${environment.gluky.productsApiBasePath}/token`, postData)
        .subscribe(
          async (res: any) => {
            resolve(res);
          },
          (err) => {
            reject(console.log("productsApiBasePath does not have permission", err));
          }
        )
    );
  }
}
